import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../Assets/Css/Batches.css"; // Importing CSS file for styling
import { BASE_URL } from "../../Constant";

const InternshipCohort = () => {
  const [CourseData, setCourseData] = useState();
  const { id } = useParams();

  const getCourse = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/internship/GetInternship`
      );
      const Course = response.data;
      const matchedCourse = Course.find((course) => course._id === id);
      if (matchedCourse) {
        setCourseData(matchedCourse);
      }
      console.log("here is the response", Course);
    } catch (error) {
      console.log("There is an error:", error);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div className="batches-container">
      <h1
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: "white",
          padding: "20px",
          backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)",
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
          margin: "20px auto",
          width: "20%",
        }}
      >
        Cohorts
      </h1>

      {CourseData ? (
        <div className="course-card">
          <h2 className="course-title">{CourseData.CompanyTitle}</h2>
          <div className="batches-list">
            <p className="batch-header"> Cohorts : </p>
            {CourseData.Internship &&
              CourseData.Internship.toReversed().map((batch, index) => (
                <div className="batch-item" key={index}>
                  <p className="batch-details">
                    <strong>{index + 1}.</strong> {batch.InternshipTitle}
                    <Link
                      to={`/InternshipUpdateEdit/${batch._id}`}
                      className="batch-link"
                    >
                      Edit
                    </Link>
                  </p>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <p className="no-data">No course data found.</p>
      )}
    </div>
  );
};

export default InternshipCohort;
