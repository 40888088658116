// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// const Batches = () => {
//   const [CourseData, setCourseData] = useState();
//   //   const [CourseId, setCourseid] = useState("");
//   const { id } = useParams();

//   const getCourse = async () => {
//     try {
//       const response = await axios.get(
//         `http://localhost:5000/api/course/GetCourse`
//       );

//       //   console.log("The response is", response.data);
//       const Course = response.data;
//       //   Course.forEach((course) => {
//       //     console.log("The Title is", course._id === id ? response.data : null);
//       //     setCourseData(course._id === id ? response.data : null);
//       //   });

//       const matchedCourse = Course.find((course) => course._id === id);
//       if (matchedCourse) {
//         console.log("The matched course is", matchedCourse);
//         setCourseData(matchedCourse);
//       }
//     } catch (error) {
//       console.log("there is error", error);
//     }
//   };

//   useEffect(() => {
//     getCourse();
//   }, []);

//   return (
//     <div style={{ padding: 10 }}>
//       Batches
//       {CourseData ? (
//         <div key={CourseData._id}>
//           <div style={{ display: "flex", flexDirection: "row", padding: 10 }}>
//             <div
//               style={{
//                 backgroundColor: "white",
//                 boxShadow:
//                   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
//                 borderRadius: 10,
//                 width: "25%",
//                 height: "25%",
//                 padding: 10,
//               }}
//             >
//               <p style={{ textAlign: "center", fontWeight: "bold" }}>
//                 {CourseData.Title}
//               </p>
//               <p style={{ fontWeight: "bold" }}>Batches : </p>
//               {CourseData.CourseBatch &&
//                 CourseData.CourseBatch.toReversed().map((batch, index) => (
//                   <div key={index}>
//                     <p>
//                       <strong>{index + 1}.</strong> {batch.BatchTitle} 
//                       <Link to={{ pathname:`/BatchupdateEdit/${batch._id}` }}>Link</Link>
//                       </p>
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </div>
//       ) : (
//         <p>No course data found.</p>
//       )}
//     </div>
//   );
// };
// export default Batches;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../Assets/Css/Batches.css" // Importing CSS file for styling
import { BASE_URL } from "../../Constant";

const Batches = () => {
  const [CourseData, setCourseData] = useState();
  const { id } = useParams();

  const getCourse = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/course/GetCourse`
      );
      const Course = response.data;
      const matchedCourse = Course.find((course) => course._id === id);
      if (matchedCourse) {
        setCourseData(matchedCourse);
      }
    } catch (error) {
      console.log("There is an error:", error);
    }
  };

  
  const DeleteBatch = async (_id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/course/DeleteBatch/${_id}`, {
        BatchId: _id 
      });
      console.log("here is the id",_id)
      alert("Batch is deleted successfully");
      console.log("Batch deleted successfully", response.data);
    } catch (error) {
      console.log("Batch not deleted ---", error.response || error.message);
    }
  };
  

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div className="batches-container">
 <h1
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: "white",
          padding: "20px",
          backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)",
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
          margin: "20px auto",
          width: "20%",
        }}
      >
        Batches
      </h1>      {CourseData ? (
        <div className="course-card">
          <h2 className="course-title">{CourseData.Title}</h2>
          <div className="batches-list">
            <p className="batch-header">Batches:</p>
            {CourseData.CourseBatch &&
              CourseData.CourseBatch.toReversed().map((batch, index) => (
                <div className="batch-item" key={index}>
                  <p className="batch-details">
                    <strong>{index + 1}.</strong> {batch.BatchTitle} 
                    <Link
                      to={`/BatchupdateEdit/${batch._id}`}
                      className="batch-link"
                    >
                      Edit
                    </Link>
                  </p>
                  {/* <p onClick={()=>DeleteBatch(batch._id)}>Delete {batch._id}</p> */}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <p className="no-data">No course data found.</p>
      )}
    </div>
  );
};


export default Batches;
