import React from "react";
import "../Assets/Css/Whiteboxes.css"
export default function (props,) {
  return (
    <div>
      <div className="white-box"
        style={{
          // borderRadius: "10px",
          // background:
          //   "linear-gradient(135deg, rgb(255, 246, 183) 10%, rgb(246, 65, 108) 100%)",
          background: props.background ,
          backgroundColor:props.backgroundColor,
          // height: "200px",
          // width: "250px",
          // boxShadow:
          //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          // display: "flex",
          // flexDirection: "row",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <p className="heading" style={{color:'white',textAlign:'center'}}>{props.heading}</p>
      </div>
    </div>
  );
}
