import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../../Assets/Login.css'; // Import the CSS file
import { BASE_URL } from "../../Constant";

const Login = () => {
  const [Mail, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [role, setRole] = useState(null);
  const [userData, setUserData] = useState("");
  const[showError,setShowError]=useState("")
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${BASE_URL}/api/auth/Login`, {
        Mail,
        Password,
      });
      
      const userData = res.data;
      localStorage.setItem("UserData", userData.role);
      localStorage.setItem("UserInfo", JSON.stringify(userData));

      setRole(userData.role);
      navigate("/");
      window.location.reload();
     console.log("Login Successfull")

    } catch (error) {
      console.error("Login failed", error);
      if(error.response.status === 400){
        console.log("Ooh bro admin is not verified yet")
        setShowError("You are not Verified yet from the Team")
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("UserData");
    if (token) {
      setRole(token);
    }
  }, []);

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Dashboard Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Email"
              value={Mail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Password"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
{/* 
<p style={{color:'red'}}>{showError}</p>
        {role === "SuperAdmin" && <SuperAdminDashboard />}
        {role === "SubAdmin" && <SubAdminDashboard />}
        {role === "Reporter" && <ReporterDashboard />}
         */}
      </div>
    </div>
  );
};

const SuperAdminDashboard = () => <div className="dashboard-content">SuperAdmin Content</div>;
const SubAdminDashboard = () => <div className="dashboard-content">SubAdmin Content</div>;
const ReporterDashboard = () => <div className="dashboard-content">Reporter Content</div>;

export default Login;
