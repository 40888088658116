import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../Assets/Css/Signup.css";
import StateAndCities from "../../StateAndCities.json";
import { BASE_URL } from "../../Constant";
const Signup = () => {
  const [showSignup, setShowsignup] = useState(false);
  const [Name, setName] = useState("");
  const [Mail, setMail] = useState("");
  const [Number, setNumber] = useState("");
  const [Password, setPassword] = useState("");
  const [AadharNumber, setAadharNumber] = useState("");
  // const [AdminState, setAdminState] = useState("");
  // const [AdminDistrict, setAdminDistrict] = useState([]);
  // const [DistrictPincode, setDistrictPincode] = useState("");
  const [DetailAddress, setDetailAddress] = useState("");
  const [Resume, setResume] = useState("");
  const [role, setrole] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const [CollegeList, SetCollegeList] = useState([]);

  //FOR COLLEGE
  const [CollegeCode, SetCollegeCode] = useState("");
  const [ContactPersonName, SetContactPersonName] = useState("");
  const [ContactPersonEmail, SetContactPersonEmail] = useState("");
  const [ContactPersonDesignation, SetContactPersonDesignation] = useState("");
  const [CollegeWebsiteUrl, SetCollegeWebsiteUrl] = useState("");
  const [CollegeType, SetCollegeType] = useState("");
  const [AffiliatedUniversity, SetAffiliatedUniversity] = useState("");
  const [NumberOfStudents, SetNumberOfStudents] = useState("");

  //FOR STUDENTS
  const [DateOfBirth, SetDateOfBirth] = useState("");
  const [Gender, SetGender] = useState("");
  const [Qualification, SetQualification] = useState("");
  const [InterestedCourse, SetInterestedCourse] = useState("");
  const [ClasstTiming, SetClassTiming] = useState("");
  const [ChooseCollege, SetChooseCollege] = useState("");
  const [FromWhereHeard, SetFromWhereHeard] = useState("");

  // const handleStateChange = (e) => {
  //   const selectedState = e.target.value;
  //   setState(selectedState);
  //   setDistrict(""); // Clear the district when a new state is selected
  // };

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Name", Name);
    formData.append("Mail", Mail);
    formData.append("Number", Number);
    formData.append("Password", Password);
    formData.append("AadharNumber", AadharNumber);
    // formData.append("State", AdminState);
    // formData.append("District", AdminDistrict);
    // formData.append("DistrictPincode", DistrictPincode);
    formData.append("DetailAddress", DetailAddress);
    formData.append("Resume", Resume);
    formData.append("role", role);

    formData.append("DateOfBirth", DateOfBirth);
    formData.append("Gender", Gender);
    formData.append("Qualification", Qualification);
    formData.append("InterestedCourse", InterestedCourse);
    formData.append("ClassTiming", ClasstTiming);
    formData.append("College", ChooseCollege);
    formData.append("FromWhereHeard", FromWhereHeard);

    formData.append("CollegeCode", CollegeCode);
    formData.append("ContactPersonName", ContactPersonName);
    formData.append("ContactPersonEmail", ContactPersonEmail);
    formData.append("ContactPersonDesignation", ContactPersonDesignation);
    formData.append("CollegeWebsiteUrl", CollegeWebsiteUrl);
    formData.append("CollegeType", CollegeType);
    formData.append("AffiliatedUniversity", AffiliatedUniversity);
    formData.append("NumberOfStudents", NumberOfStudents);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/auth/Signup`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      console.log("Subadmin data uploaded successfully", response.data);
      // if (response.status === 401) {
      //   console.log("Email or number already registered");
      // }
      alert("subadmin created");
      setShowsignup(false);
      setName("");
      setMail("");
      setNumber("");
      setPassword("");
      setAadharNumber("");
      setDetailAddress("");
      setResume("");
      setrole("");
    } catch (error) {
      console.log(
        "subadmin data not uploaded ---",
        error.response || error.message
      );
      if (error.status === 401) {
        console.log("Email or number already registered");
        setErrorMessage("Email or number already registered");
      }
    }
  };

  const GetUsers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/auth/GetUsers`);
      const userData = res.data;
      // console.log("list of users", userData);

      const college = userData.filter((item) => item.role === "College");
      SetCollegeList(college);
      console.log("College List", college);

      // console.log("List of trainers", trainers);
    } catch (error) {
      console.error("Unable to fetch data", error);
    }
  };

  useEffect(() => {
    GetUsers();
  },[]);

  return (
    <div
      style={{
        marginTop: "10px",
        backgroundColor: "whitesmoke",
        padding: "10px",
        color: "black",
        boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="Signup-box">
        <div className="form-group">
          <h2 style={{ textAlign: "center" }}>Create Account 🤘</h2>
          <p style={{ color: "red" }}>{ErrorMessage}</p>
          <label>Name</label>
          <input
            type="text"
            value={Name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <label>Mail</label>

          <input
            type="email"
            value={Mail}
            placeholder="Mail"
            onChange={(e) => setMail(e.target.value)}
          />

          <label>Password</label>

          <input
            type="password"
            value={Password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Number</label>

          <input
            type="number"
            value={Number}
            placeholder="Number"
            onChange={(e) => setNumber(e.target.value)}
          />

          <label>Detail Address</label>

          <input
            type="text"
            value={DetailAddress}
            placeholder="Detail Address"
            onChange={(e) => setDetailAddress(e.target.value)}
          />

          <label>Aadhar Number</label>

          <input
            type="Number"
            value={AadharNumber}
            placeholder="Aadhar Number"
            onChange={(e) => setAadharNumber(e.target.value)}
          />

          <label>Role</label>

          <select
            name="role"
            id="role"
            onChange={(e) => setrole(e.target.value)}
          >
            <option value="none">None</option>
            <option value="Trainer">Trainer</option>
            <option value="Student">Student</option>
            <option value="College">College</option>
          </select>

          {role === "Trainer" ? (
            <div>
              <label>Resume</label>

              <input
                type="file"
                placeholder="Resume"
                onChange={(e) => setResume(e.target.files[0])}
              />
            </div>
          ) : null}

          {role === "College" ? (
            <div>
              <label>College Code</label>
              <input
                type="text"
                placeholder="College Code"
                value={CollegeCode}
                onChange={(e) => SetCollegeCode(e.target.value)}
              />

              <label>Contact Person Name</label>

              <input
                type="text"
                placeholder="Contact Person Name"
                value={ContactPersonName}
                onChange={(e) => SetContactPersonName(e.target.value)}
              />

              <label>Contact Person Email</label>
              <input
                type="email"
                placeholder="Contact Person Email"
                value={ContactPersonEmail}
                onChange={(e) => SetContactPersonEmail(e.target.value)}
              />

              <label>Contact Person Designation</label>
              <select
                name="Contact-Person-Designation"
                id="Contact-Person-Designation"
                onChange={(e) => SetContactPersonDesignation(e.target.value)}
              >
                <option value="none">None</option>
                <option value="HOD">HOD</option>
                <option value="Placement-Officer">Placement Officer</option>
              </select>

              <label>College Website Url</label>
              <input
                type="url"
                placeholder="Website Url"
                value={CollegeWebsiteUrl}
                onChange={(e) => SetCollegeWebsiteUrl(e.target.value)}
              />

              <label>College Type</label>

              <select
                name="Type"
                id="Type"
                onChange={(e) => SetCollegeType(e.target.value)}
              >
                <option value="none">None</option>
                <option value="Government">Government</option>
                <option value="Private">Private</option>
                <option value="Autonomous">Autonomous</option>
              </select>

              <label>Affiliated University</label>
              <input
                type="text"
                placeholder="Affiliated University"
                value={AffiliatedUniversity}
                onChange={(e) => SetAffiliatedUniversity(e.target.value)}
              />

              <label>Number of Students</label>
              <input
                type="number"
                placeholder="Number of Students"
                value={NumberOfStudents}
                onChange={(e) => SetNumberOfStudents(e.target.value)}
              />
            </div>
          ) : null}

          {role === "Student" ? (
            <div>
              <label>Date of Birth</label>

              <input
                type="date"
                placeholder="DOB"
                value={DateOfBirth}
                onChange={(e) => SetDateOfBirth(e.target.value)}
              />

              <label>Gender</label>

              <select
                name="Gender"
                id="Gender"
                onChange={(e) => SetGender(e.target.value)}
              >
                <option value="none">Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>

              <label>Qualfication</label>

              <select
                name="EducationQualification"
                id="EducationQualification"
                onChange={(e) => SetQualification(e.target.value)}
              >
                <option value="none">Qualfication</option>
                <option value="10plus">10 +</option>
                <option value="12plus">12 +</option>
                <option value="12plus">DIPLOMA</option>

                <option value="BA">BA</option>
                <option value="BSC">BSC</option>
                <option value="BCA">BCA</option>
                <option value="BCOM">BCOM</option>
                <option value="BTECH">BTECH</option>

                <option value="Other-UG">---Other UG---</option>

                <option value="MA">MA</option>
                <option value="MSC">MSC</option>
                <option value="MCA">MCA</option>
                <option value="MTECH">MTECH</option>
                <option value="MCOM">MCOM</option>

                <option value="Other_PG">---Other PG---</option>

                <option value="PHD">PHD</option>
                <option value="OTHER">OTHER</option>
              </select>

              <label>Course of Interest</label>

              <select
                name="Course-Interested"
                id="course-Interested"
                onChange={(e) => SetInterestedCourse(e.target.value)}
              >
                <option value="none">COURSE OF Interest</option>
                <option value="Fullstack-Development">
                  Fullstack Development
                </option>
                <option value="Data-Science">Data Science</option>
                <option value=">Digital-Marketing">Digital Marketing</option>
                <option value="Medical-Coding">Medical Coding</option>
                <option value="Power-BI">Power BI</option>
                <option value="Tableau">Tableau</option>
                <option value="OTHER">OTHER</option>
              </select>

              <label>Preferred Classes</label>

              <select
                name="Preferred-Time-For-Class"
                id="Preferred-Time-For-Class"
                onChange={(e) => SetClassTiming(e.target.value)}
              >
                <option value="none">Preferred Time for classes</option>
                <option value="Morning">Morning</option>
                <option value="Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
              </select>

              <label>Choose your college</label>

              {/* <select
                name="College/University"
                id="College/University"
                onChange={(e) => SetChooseCollege(e.target.value)}
              >
                <option value="none">College / University</option>
                <option value="abc">abc</option>
                <option value="xyz">xyz</option>
              </select> */}

              {CollegeList.map((item, index) => (
                <select
                  id="College/University"
                  name="College/University"
                  onChange={(e) => SetChooseCollege(e.target.value)}
                  // onClick={GetUsers}
                >
                  <option value="none">College / University</option>

                  {/* <option value="xyz">xyz</option>
                            <option value="abc">abc</option> */}
                  <option value={item.Name}>{item.Name}</option>
                </select>
              ))}

              <label>From where you heard about us ?</label>

              <select
                name="Heared"
                id="Heared"
                onChange={(e) => SetFromWhereHeard(e.target.value)}
              >
                <option value="none">----</option>
                <option value="Friend">Friend</option>
                <option value="Social Media">Social Media</option>
                <option value="Website">Website</option>
                <option value="Advertisement">Advertisement</option>
              </select>
            </div>
          ) : null}

          <button onClick={submitHandler}>Submit</button>
        </div>
      </div>
    </div>
  );
};
export default Signup;
