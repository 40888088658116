import React, { useEffect, useState } from "react";
import home from "../Assets/home.png";
import info from "../Assets/info.png";
import copyWwriting from "../Assets/copy-writing.png";
import totalusers from "../Assets/totalusers.png";
import immigiration from "../Assets/immigiration.png";
import administrator from "../Assets/administrator.png";
import Whiteboxes from "../Components/whiteboxes";
import Cards from "../Components/Cards";
import { Link } from "react-router-dom";
import "../Assets/Css/Dashboard.css";
export default function Dashboard() {
  const [userDashboardRole, setuserDashboard] = useState(null);
  const [totalnews, settotalNews] = useState("");
  const [userRole, setuserRole] = useState("");
  // useEffect(() => {
  //   const whichUserSignedIn = localStorage.getItem("UserInfo");

  //   if (whichUserSignedIn) {
  //     const userInfo = JSON.parse(whichUserSignedIn);
  //     setuserDashboard(userInfo.Mail);
  //     console.log("User's Mail:", userInfo.Mail);
  //   }

  //   setuserDashboard(whichUserSignedIn);
  //   // console.log(".............",whichUserSignedIn)
  // }, []);

  // useEffect(() => {
  //   const whichUserSignedIn = localStorage.getItem("UserInfo");

  //   if (whichUserSignedIn) {
  //     const userInfo = JSON.parse(whichUserSignedIn);
  //     setuserDashboard(userInfo.Mail);
  //     console.log("User's Mail:", userInfo.Mail);
  //   }
  // }, []);

  useEffect(() => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userInfo = JSON.parse(whichUserSignedIn);

        if (userInfo && userInfo.UserId && userInfo.UserId.Mail) {
          setuserDashboard(userInfo.UserId.Mail);

          console.log("User's Mail:", userInfo.UserId.role);
          setuserRole(userInfo.UserId.role);
        } else {
          console.log("Mail property not found in UserInfo.");
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("UserInfo not found in localStorage.");
    }
  }, []);

  return (
    <div>
      <div
        className="Dashboard"
        // style={{
        //   marginTop: "10px",
        //   marginRight: "100px",
        //   marginLeft: "100px",
        //   backgroundColor: "white",
        //   padding: "10px",
        //   color: "black",
        //   boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
        // }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                // backgroundColor: "#663dff",
                height: "50px",
                width: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px",
                background:
                  "linear-gradient(110.6deg, rgb(184, 142, 252) 2.2%, rgb(104, 119, 244) 100.2%)",
                // background: 'linear-gradient(to bottom, #fb83fa 50%,#e93cec 100%',
                borderRadius: "5px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
              }}
            >
              <img style={{ maxHeight: "50%", maxWidth: "50%" }} src={home} />
            </div>

            <div>
              <p style={{ fontWeight: "600", fontSize: "18px" }}>Dashboard </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <p> Overview</p>
            <img style={{ height: "20px", width: "20px" }} src={info} />
          </div>
        </div>

        {/* {userDashboardRole === "SuperAdmin" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              padding: "10px",
              gap: "10px",
              // marginTop:'10px'
            }}
          >
            <Cards
              background="linear-gradient(135deg, rgb(255, 246, 183) 10%, rgb(246, 65, 108) 100%)"
              title="Total Articles"
              total="8972"
              lastweek="Last Week : 7000"
              image={copyWwriting}
            />

            <Cards
              background="linear-gradient(110.6deg, rgb(184, 142, 252) 2.2%, rgb(104, 119, 244) 100.2%)"
              title="Total Users"
              total="50000"
              lastweek="Last Week : 30000"
              image={totalusers}
            />

            <Cards
              background="linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)"
              title="Total Reporters"
              total="2000"
              lastweek="Last Week : 1920"
              image={immigiration}
            />

            <Cards
              background="radial-gradient(circle at 4.3% 10.7%, rgb(138, 118, 249) 13.6%, rgb(75, 252, 235) 100.7%)"
              title="Total Sub Admins"
              total="500"
              lastweek="Last Week : 300"
              image={administrator}
            />
          </div>
        )} */}

        {/* <p style={{ fontWeight: "600", fontSize: "18px" }}>Manage Academics</p> */}

        {userRole === "SuperAdmin" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: "10px",
            }}
          >
            <Link style={{ textDecoration: "none" }} to="/CreateCourse">
              <Whiteboxes
                heading="Create Course"
                backgroundColor="white"
                background="radial-gradient(circle at 4.3% 10.7%, rgb(138, 118, 249) 13.6%, rgb(75, 252, 235) 100.7%)"
              />
            </Link>

            <>
              <Link style={{ textDecoration: "none" }} to="/CreateBatch">
                <Whiteboxes
                  heading="Create Batches"
                  backgroundColor="white"
                  background="linear-gradient(to top, #007adf 0%, #00ecbc 100%)"
                />
              </Link>
              <Link style={{ textDecoration: "none" }} to="/CreateInternship">
                <Whiteboxes
                  heading="Create Internships"
                  backgroundColor="white"
                  background="linear-gradient(to top, #007adf 0%, #00ecbc 100%)"
                />
              </Link>
            </>

            <>
              <Link
                style={{ textDecoration: "none" }}
                to="/CreateInternshipCohort"
              >
                <Whiteboxes
                  heading="Create Internship Cohort"
                  backgroundColor="white"
                  background="radial-gradient(circle at 4.3% 10.7%, rgb(138, 118, 249) 13.6%, rgb(75, 252, 235) 100.7%)"
                />
              </Link>
            </>


            <>
              <Link
                style={{ textDecoration: "none" }}
                to="/StudentCredentials"
              >
                <Whiteboxes
                  heading="User Credentials"
                  backgroundColor="white"
                  background="radial-gradient(circle at 4.3% 10.7%, rgb(138, 118, 249) 13.6%, rgb(75, 252, 235) 100.7%)"
                />
              </Link>
            </>
            {/* )} */}
          </div>
        )}

        {userRole === "Student" && (
          <div>
            Student
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "10px",
                }}
              >
                <Link
                  style={{ textDecoration: "none" }}
                  to="/CourseRegistration"
                >
                  <Whiteboxes
                    heading="Apply for Course"
                    backgroundColor="white"
                    background="radial-gradient(circle at 4.3% 10.7%, rgb(138, 118, 249) 13.6%, rgb(75, 252, 235) 100.7%)"
                  />
                </Link>

                <>
                  <Link to="/StudentBatches" style={{ textDecoration: "none" }}>
                    <Whiteboxes
                      heading="Your Batches"
                      backgroundColor="white"
                      background="linear-gradient(to top, #007adf 0%, #00ecbc 100%)"
                    />
                  </Link>
                </>

                <>
                  <Link to="/InternshipCohortBatches" style={{ textDecoration: "none" }}>
                    <Whiteboxes
                      heading="Internship Cohort"
                      backgroundColor="white"
                      background="linear-gradient(to top, #007adf 0%, #00ecbc 100%)"
                    />
                  </Link>
                </>
              </div>
            </div>
          </div>
        )}

        {userRole === "Trainer" && (
          <div>
            {/* Student */}
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: "10px",
                }}
              >
                <Link
                  style={{ textDecoration: "none" }}
                  to="/TrainerBatchesList"
                >
                  <Whiteboxes
                    heading="Upload Courses Lectures"
                    backgroundColor="white"
                    background="radial-gradient(circle at 4.3% 10.7%, rgb(138, 118, 249) 13.6%, rgb(75, 252, 235) 100.7%)"
                  />
                </Link>
                <Link
                  to="/TrainerInternshipList"
                  style={{ textDecoration: "none" }}
                >
                  <Whiteboxes
                    heading="Upload Internship lectures"
                    backgroundColor="white"
                    background="linear-gradient(to top, #007adf 0%, #00ecbc 100%)"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
