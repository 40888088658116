import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../Constant";

export default function UploadLecture() {
  const [TrainerBatches, setTrainerBatches] = useState([]);
  const [lectureInputs, setLectureInputs] = useState({});
  const { batchid } = useParams();

  const AddLecture = async (batchid) => {
    try {
      const {
        title,
        link,
        details,
        duration,
        // lecturePdf,
        // recordinglink,
        // tasktitle,
        // taskdescription,
        // taskStartDate,
        // taskEndDate,
      } = lectureInputs[batchid] || {};
      const payload = {
        BatchId: batchid,
        title,
        link,
        details,
        duration,
        // lecturePdf,
        // tasktitle,
        // recordinglink,
        // taskdescription,
        // taskStartDate,
        // taskEndDate,
      };
      console.log("Sending payload:", payload); // Log payload

      const response = await axios.patch(
        `${BASE_URL}/api/course/AddLectures/${batchid}`,
        payload
      );

      alert("Lecture added to batch");
      console.log("Response.....................:", response.data); // Log successful response
    } catch (error) {
      console.log("Request failed:", error.response?.data || error.message); // Log any error details
    }
  };

  const handleInputChange = (batchId, field, value) => {
    setLectureInputs((prevInputs) => ({
      ...prevInputs,
      [batchId]: {
        ...prevInputs[batchId],
        [field]: value,
      },
    }));
  };

  return (
    <div>
      uploadLecture
      <p>{batchid}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        {/* <p style={{ color: "red" }} onClick={() => setlecturebox(null)}>
          Close
        </p> */}

        <input
          type="text"
          placeholder="lecture title"
          value={lectureInputs[batchid]?.title || ""}
          onChange={(e) => handleInputChange(batchid, "title", e.target.value)}
        />

        <input
          type="text"
          placeholder="lecture link"
          // value={title}
          //  onChange={(e) => SetLink(e.target.value)}
          value={lectureInputs[batchid]?.link || ""}
          onChange={(e) => handleInputChange(batchid, "link", e.target.value)}
        />

        <input
          type="text"
          placeholder="lecture details"
          value={lectureInputs[batchid]?.details || ""}
          onChange={(e) =>
            handleInputChange(batchid, "details", e.target.value)
          }
        />

        <input
          type="text"
          placeholder="lecture durations"
          value={lectureInputs[batchid]?.duration || ""}
          onChange={(e) =>
            handleInputChange(batchid, "duration", e.target.value)
          }
        />

<input
          type="file"
          placeholder="file"
          value={lectureInputs[batchid]?.duration || ""}
          onChange={(e) =>
            handleInputChange(batchid, "duration", e.target.value)
          }
        />
     

        <div
          style={{
            color: "white",
            backgroundColor: "black",
            borderRadius: "10px",
            padding: "10px",
          }}
          onClick={() => AddLecture(batchid)}
        >
          Submit
        </div>
      </div>
    </div>
  );
}
