import React from "react";
import "../../Assets/Css/HomePage.css";
import { ServiceCard } from "./ServiceCard";
import { Link } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Welcome to Bilvens</h1>
          <p>
            Your gateway to the best solutions in IT, Finance, Management,
            Non-IT, and Pharmaceuticals.
          </p>
          <Link to="/login">
            <button className="cta-btn">Get Started</button>
          </Link>
        </div>
      </section>

      {/* Service Cards Section */}
      <section className="services">
        <h2>Our Services</h2>
        <div className="service-cards">
          <ServiceCard
            title="IT Solutions"
            description="Innovative technology solutions to propel your business forward."
            bgColor="#4CAF50"
          />
          <ServiceCard
            title="Finance"
            description="Comprehensive financial services to help you achieve your goals."
            bgColor="#2196F3"
          />
          <ServiceCard
            title="Management"
            description="Expert management solutions to optimize your business operations."
            bgColor="#FFC107"
          />
          <ServiceCard
            title="Non-IT"
            description="End-to-end services to manage non-IT aspects of your business."
            bgColor="#FF5722"
          />
          <ServiceCard
            title="Pharmaceuticals"
            description="Leading pharmaceutical solutions to support your health initiatives."
            bgColor="#9C27B0"
          />
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 All Rights Reserved. Bilvens.</p>
      </footer>
    </div>
  );
};

export default App;
