import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constant";
import { Link } from "react-router-dom";

export default function TrainerBatchesList() {
  const [userMail, setUserMail] = useState("");
  const [userRole, setuserRole] = useState("");
  const [courses, setCourses] = useState();
  const [TrainerBatches, setTrainerBatches] = useState([]);
  const [lectureInputs, setLectureInputs] = useState({});
  const [lecturebox, setlecturebox] = useState(null);
  // const [title, SetTitle] = useState("");
  // const [link, SetLink] = useState("");
  // const [details, SetDetails] = useState("");
  // const [duration, SetDuration] = useState("");

  const LoggedUser = () => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userInfo = JSON.parse(whichUserSignedIn);

        if (userInfo && userInfo.UserId && userInfo.UserId.Mail) {
          setUserMail(userInfo.UserId.Mail);
          setuserRole(userInfo.UserId.role);

          console.log(
            "User's Mail:............................",
            userInfo.UserId.Mail
          );
          console.log("User's Mail:", userInfo.UserId.role);
          console.log("User's Mail:", userInfo.UserId.Name);
        } else {
          console.log("Mail property not found in UserInfo.");
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("UserInfo not found in localStorage.");
    }
  };

  const getCourse = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/course/GetCourse`);
      const courses = response.data;
      console.log("Courses list", courses);

      // console.log(
      //   "Courses Batch",
      //   courses.map((item) =>
      //     item.CourseBatch.map((Batches) =>
      //       Batches.BatchTrainerEmail === userMail ? Batches.BatchTitle : null
      //     )
      //   )
      // );

      const trainerBatchli = courses.map((item) =>
        item.CourseBatch.filter((batch) => batch.BatchTrainerEmail === userMail)
          .map((batch) => batch.BatchTitle)
          .filter((batchTitles) => batchTitles.length > 0)
      );

      console.log("Courses Batch...", trainerBatchli);

      console.log("User mail is", userMail);

      // const trainerBatches = courses.map((item) =>
      //   item.CourseBatch.map((Batches) =>
      //     Batches.BatchTrainerEmail === userMail ? Batches : null
      //   )
      // );

      const trainerBatches = courses.flatMap((item) =>
        item.CourseBatch.filter((batch) => batch.BatchTrainerEmail === userMail)
      );
      setTrainerBatches(trainerBatches);
      setCourses(courses);

      // let matchedBatch = null;
      // courses.forEach((course) => {
      //   const foundBatch = course.CourseBatch.find((batch) => batch._id === id);
      //   if (foundBatch) {
      //     matchedBatch = { ...foundBatch }; // Store course title if needed
      //   }
      // });

      // if (matchedBatch) {
      //   console.log("The matched batch details:");
      //   console.log("Batch Title:", matchedBatch.BatchTitle); // Log BatchTitle
      //   console.log("Batch Details:", matchedBatch.BatchDetails); // Log BatchDetails
      //   setBatchData(matchedBatch);
      // } else {
      //   console.log("No batch found with the given ID");
      // }
    } catch (error) {
      console.log("there is error", error);
    }
  };

  useEffect(() => {
    LoggedUser();
  }, []);

  useEffect(() => {
    if (userMail) {
      getCourse();
    }
  }, [userMail]);

  // const AddLecture = async (_id) => {
  //   try {
  //     const { title, link, details, duration } = lectureInputs[_id] || {};

  //     const payload = {
  //       BatchId: _id,
  //       title,
  //       link,
  //       details,
  //       duration,
  //     };
  //     await axios.patch(`${BASE_URL}/api/course/AddLectures/${_id}`, payload);
  //     alert("Lecture added to batch");
  //     console.log("Lecture added to batch");
  //   } catch (error) {
  //     console.log("some error", error);
  //   }
  // };

  // const AddLecture = async (_id) => {
  //   try {
  //     const {
  //       title,
  //       link,
  //       details,
  //       duration,
  //       recordinglink,
  //       tasktitle,
  //       taskdescription,
  //       taskStartDate,
  //       taskEndDate,
  //       lecturePdf,
  //     } = lectureInputs[_id] || {};
  //     const payload = {
  //       BatchId: _id,
  //       title,
  //       link,
  //       details,
  //       duration,
  //       tasktitle,
  //       recordinglink,
  //       taskdescription,
  //       taskStartDate,
  //       taskEndDate,
  //       lecturePdf
  //     };
  //     console.log("Sending payload:", payload); // Log payload

  //     const response = await axios.patch(
  //       `${BASE_URL}/api/course/AddLectures/${_id}`,
  //       payload
  //     );

  //     alert("Lecture added to batch");
  //     console.log("Response.....................:", response.data); // Log successful response
  //     setLectureInputs((prevInputs) => ({
  //       ...prevInputs,
  //       [_id]: { title: "", link: "", details: "", duration: "" },
  //     }));
  //   } catch (error) {
  //     console.log("Request failed:", error.response?.data || error.message); // Log any error details
  //   }
  // };

  const AddLecture = async (_id) => {
    try {
      const {
        title,
        link,
        details,
        duration,
        recordinglink,
        tasktitle,
        taskdescription,
        taskStartDate,
        taskEndDate,
        lecturePdf,
      } = lectureInputs[_id] || {};
  
      // Create a new FormData instance
      const formData = new FormData();
      formData.append("BatchId", _id);
      formData.append("title", title);
      formData.append("link", link);
      formData.append("details", details);
      formData.append("duration", duration);
      formData.append("tasktitle", tasktitle);
      formData.append("recordinglink", recordinglink);
      formData.append("taskdescription", taskdescription);
      formData.append("taskStartDate", taskStartDate);
      formData.append("taskEndDate", taskEndDate);
  
      // Append the file only if it exists
      if (lecturePdf) {
        formData.append("lecturePdf", lecturePdf);
      }
  
      const response = await axios.patch(
        `${BASE_URL}/api/course/AddLectures/${_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        }
      );
  
      alert("Lecture added to batch");
      console.log("Response:", response.data);
  
      setLectureInputs((prevInputs) => ({
        ...prevInputs,
        [_id]: {
          title: "",
          link: "",
          details: "",
          duration: "",
          recordinglink: "",
          tasktitle: "",
          taskdescription: "",
          taskStartDate: "",
          taskEndDate: "",
          lecturePdf: null,
        },
      }));
    } catch (error) {
      console.error("Request failed:", error.response?.data || error.message);
    }
  };
  
  const handleInputChange = (batchId, field, value) => {
    setLectureInputs((prevInputs) => ({
      ...prevInputs,
      [batchId]: {
        ...prevInputs[batchId],
        [field]: value,
      },
    }));
  };

  // const uploadbox = () => {
  //   setlecturebox(true);
  // };

  return (
    <div>
      <p
        style={{
          fontWeight: "bold",
          fontFamily: "monospace",
          fontSize: "24px",
        }}
      >
        {" "}
        Your Batches 📙
      </p>
      {/* <p> {userMail}</p> */}
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        {TrainerBatches.map((batch) => (
          <div
            key={batch._id}
            style={{
              margin: "10px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "auto",
              // height: "200px",
              backgroundColor: "white",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              color: "black",
              borderRadius: "8px",
              cursor: "pointer",
              borderColor: "red",
            }}
          >
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {batch.BatchTitle}
            </p>
            <ul>
              <li
                style={{
                  textAlign: "left",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                {batch.BatchDetails}
              </li>
            </ul>

            {/* <Link to={{ pathname: `/UploadLecture/${batch._id}` }}>Link</Link> */}

            {/* <p>{batch._id}</p> */}
            <div
              style={{ color: "purple", fontWeight: "bold" }}
              onClick={() => setlecturebox(batch._id)}
            >
              Upload Lecture
            </div>

            {lecturebox === batch._id && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <p style={{ color: "red" }} onClick={() => setlecturebox(null)}>
                  Close
                </p>

                <input
                  type="text"
                  placeholder="lecture title"
                  value={lectureInputs[batch._id]?.title || ""}
                  onChange={(e) =>
                    handleInputChange(batch._id, "title", e.target.value)
                  }
                />

                <input
                  type="text"
                  placeholder="lecture link"
                  // value={title}
                  //  onChange={(e) => SetLink(e.target.value)}
                  value={lectureInputs[batch._id]?.link || ""}
                  onChange={(e) =>
                    handleInputChange(batch._id, "link", e.target.value)
                  }
                />

                <input
                  type="text"
                  placeholder="lecture details"
                  value={lectureInputs[batch._id]?.details || ""}
                  onChange={(e) =>
                    handleInputChange(batch._id, "details", e.target.value)
                  }
                />

                <input
                  type="text"
                  placeholder="lecture durations"
                  value={lectureInputs[batch._id]?.duration || ""}
                  onChange={(e) =>
                    handleInputChange(batch._id, "duration", e.target.value)
                  }
                />

                <input
                  type="text"
                  placeholder="Task Title"
                  value={lectureInputs[batch._id]?.tasktitle || ""}
                  onChange={(e) =>
                    handleInputChange(batch._id, "tasktitle", e.target.value)
                  }
                />

                <input
                  type="text"
                  placeholder="Recording Link"
                  value={lectureInputs[batch._id]?.recordinglink || ""}
                  onChange={(e) =>
                    handleInputChange(
                      batch._id,
                      "recordinglink",
                      e.target.value
                    )
                  }
                />

                <input
                  type="text"
                  placeholder="Task Description"
                  value={lectureInputs[batch._id]?.taskdescription || ""}
                  onChange={(e) =>
                    handleInputChange(
                      batch._id,
                      "taskdescription",
                      e.target.value
                    )
                  }
                />

                <input
                  type="datetime-local"
                  placeholder="Task Start Date"
                  value={lectureInputs[batch._id]?.taskStartDate || ""}
                  onChange={(e) =>
                    handleInputChange(
                      batch._id,
                      "taskStartDate",
                      e.target.value
                    )
                  }
                />

                <input
                  type="datetime-local"
                  placeholder="Task End Date"
                  value={lectureInputs[batch._id]?.taskEndDate || ""}
                  onChange={(e) =>
                    handleInputChange(batch._id, "taskEndDate", e.target.value)
                  }
                />

                <input
                  type="file"
                  placeholder="File"
                  //value={lectureInputs[batch._id]?.lecturePdf || ""}
                  onChange={(e) =>
                    handleInputChange(batch._id, "lecturePdf",  e.target.files[0])
                  }
                />
                <div
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  onClick={() => AddLecture(batch._id)}
                >
                  Submit
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
