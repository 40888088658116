import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Assets/Css/Upload.css";
import { BASE_URL } from "../Constant";
const Upload = () => {
  // const [RestaurantName, setRestaurantName] = useState("");
  const [NewsTitle, setNewsTitle] = useState("");
  const [newsDetails, setNewsDetails] = useState("");
  const [newsReference, setNewsReference] = useState("");
  const [newsLanguage, setNewsLanguage] = useState("");
  const [newsState, setNewsState] = useState("");
  const [newsDistrict, setNewsDistrict] = useState("");
  const [newsReportername, setNewsReporterName] = useState("");
  const [newsDistrictPin, setNewsDistrictPin] = useState("");
  const [newsImage, setNewsImage] = useState("");
  const [user, setuser] = useState("");
  const [adminlist, setadminlist] = useState([]);
  const [userDashboardRole, setuserDashboard] = useState(null);
  const [userName, setUserName] = useState("");
  const [userState, setUserState] = useState("");
  const [userDistrict, setUserDistrict] = useState("");
  //Mail ,number and id are not defined in news schema so don't use them
  const [userMail, setUserMail] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userID, setUserId] = useState("");

  const [subadminName, setSubAdminName] = useState("");
  const [subadminMail, setSubAdminMail] = useState("");
  const [subadminNumber, setSubAdminNumber] = useState("");
  const [subadminID, setSubAdminId] = useState("");
  const [NewsType, setNewsType] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("NewsTitle", NewsTitle);
    formData.append("NewsDetails", newsDetails);
    formData.append("NewsReference", newsReference);
    formData.append("NewsLanguage", newsLanguage);
    formData.append("NewsState", userState);
    formData.append("NewsDistrict", userDistrict);
    formData.append("NewsDistrictPin", newsDistrictPin);
    formData.append("NewsImage", newsImage);
    formData.append("NewsReporterName", userName);
    formData.append("NewsReporterMail", userMail);
    formData.append("NewsReporterNumber", userNumber);
    formData.append("NewsReporterId", userID);
    formData.append("NewsReporterSubAdminName", subadminName);
    formData.append("NewsReporterSubAdminMail", subadminMail);
    formData.append("NewsReporterSubAdminNumber", subadminNumber);
    formData.append("NewsReporterSubAdminId", subadminID);
    formData.append("NewsType", NewsType);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/UploadNews/uploadnewsdata`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      alert("your news is uploaded");
      setNewsTitle("");
      setNewsDetails("");
      setNewsReference("");
      setNewsLanguage("");
      setNewsType("");
      setNewsImage("");
      setNewsDistrictPin("");

      console.log("News uploaded successfully", response.data);
    } catch (error) {
      console.log("News not uploaded ---", error.response || error.message);
    }
  };

  useEffect(() => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userinfo = JSON.parse(whichUserSignedIn); // Parse only if valid
        setuser(userinfo);
        setUserName(userinfo.AdminName);
        setUserState(userinfo.AdminState);
        setUserDistrict(userinfo.AdminDistrict);
        setUserMail(userinfo.AdminMail);
        setUserNumber(userinfo.AdminNumber);
        setUserId(userinfo._id);
        // setUserId(userinfo.role);

        console.log(
          "The user is...............",
          userinfo.AdminMail,
          ",",
          userinfo.AdminName,
          userinfo._id
        ); // Access the AdminName property
      } catch (error) {
        console.error("Error parsing user info:", error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchAdmindata = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/SubAdmin/getAllAdminlist`
        );
        console.log("this is the response", response);
        setadminlist(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAdmindata();
  }, []);

  useEffect(() => {
    const whichUserSignedIn = localStorage.getItem("UserData");
    setuserDashboard(whichUserSignedIn);
  }, []);

  const filteredAdmins = adminlist.filter(
    (admin) =>
      admin.AdminApproved === true &&
      admin.role === "SubAdmin" &&
      admin.AdminState === user.AdminState &&
      admin.AdminDistrict === user.AdminDistrict
  );
  
  useEffect(() => {
    const filteredName = filteredAdmins.map((admin) => admin.AdminName);
    const filteredMail = filteredAdmins.map((admin) => admin.AdminMail);
    const filteredNumber = filteredAdmins.map((admin) => admin.AdminNumber);
    const filteredId = filteredAdmins.map((admin) => admin._id);

    const subAdminNameString = filteredName.join(", ");
    const subAdminMailString = filteredMail.join(", ");
    const subAdminNumberString = filteredNumber.join(", ");
    const subAdminIdString = filteredId.join(", ");

    console.log("sub admin name is ", subAdminNameString);
    console.log("sub admin mail is ", subAdminMailString);

    setSubAdminName(subAdminNameString);
    setSubAdminMail(subAdminMailString);
    setSubAdminNumber(subAdminNumberString);
    setSubAdminId(subAdminIdString);
  });

  return (
    <div
      style={{
        marginTop: "10px",
        // marginRight: "100px",
        // marginLeft: "100px",
        backgroundColor: "white",
        padding: "10px",
        color: "black",
        boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <p>
        {user.AdminName}:{user.AdminMail},{user.AdminState},{user.AdminDistrict}
      </p> */}
      {userDashboardRole === "Reporter" && (
        <>
          {filteredAdmins.length > 0 ? (
            filteredAdmins.map((admin) => (
              <p key={admin._id}>
                {admin.AdminName} : {admin.AdminMail}
                {admin.AdminState}, {admin.AdminDistrict}, ,{admin.role}
              </p>
            ))
          ) : (
            <p>No SubAdmins found for the selected state and district.</p>
          )}
        </>
      )}

      <div className="box">
        <form className="form-group">
          <h1 style={{ textAlign: "center" }}>Upload News</h1>
          <label>News Title</label>
          <input
            // style={{ width: "200px" }}
            type="text"
            placeholder="News title"
            value={NewsTitle}
            onChange={(e) => setNewsTitle(e.target.value)}
          />
          <label>News Details</label>

          <textarea
            // style={{ height: "50px", marginTop: "10px", width: "200px" }}
            type="text"
            placeholder="Write the short summary of the news here...."
            value={newsDetails}
            onChange={(e) => setNewsDetails(e.target.value)}
          />
          <label>News Reference</label>

          <input
            // style={{ marginTop: "10px", width: "200px" }}
            type="text"
            placeholder="News Reference"
            value={newsReference}
            onChange={(e) => setNewsReference(e.target.value)}
          />
          <label>News Language</label>

          <select
            // style={{ marginTop: "10px", width: "200px" }}
            id="NewsLanguage"
            name="NewsLanguage"
            value={newsLanguage}
            onChange={(e) => setNewsLanguage(e.target.value)}
          >
            <option value="None">Language</option>
            <option value="Hindi">Hindi</option>
            <option value="Enlgish">Enlgish</option>
            <option value="Bengali">Bengali </option>
            <option value="Marathi">Marathi </option>
            <option value="Telugu">Telugu </option>
            <option value="Tamil">Tamil </option>
            <option value="Gujarati">Gujarati </option>
            <option value="Kannada">Kannada </option>
            <option value="Odia">Odia </option>
            <option value="Malayalam">Malayalam </option>
            <option value="Punjabi">Punjabi </option>
            <option value="Maithili">Maithili </option>
            <option value="Santali">Santali </option>
            <option value="Kashmiri">Kashmiri </option>
            <option value="Nepali">Nepali </option>
            <option value="Konkani">Konkani </option>
            <option value="Sindhi">Sindhi </option>
            <option value="Manipuri Meitei">Manipuri Meitei </option>
            <option value="Bodo">Bodo </option>
            <option value="Sanskrit">Sanskrit </option>
          </select>

          <label>News Type</label>

          <select
            // style={{ marginTop: "10px", width: "200px" }}
            id="NewsType"
            name="NewsType"
            value={NewsType}
            onChange={(e) => setNewsType(e.target.value)}
          >
            <option value="None">News Type</option>
            <option value="General">General</option>
            <option value="Business">Business</option>
            <option value="Political">Political </option>
            <option value="Entertainment">Entertainment </option>
            <option value="Editorial">Editorial </option>
            <option value="Lifestyle">Lifestyle </option>
            <option value="Health">Health </option>
            <option value="Startup">Startup </option>
            <option value="AI">AI </option>
            <option value="Olympics">Olympics </option>
            <option value="Games">Games </option>
            <option value="World News ">World News </option>
            <option value="Agriculture">Agriculture </option>
            <option value="Arts">Arts </option>
            <option value="Advertisement">Advertisement </option>
            {(userDashboardRole === "SubAdmin" ||
              userDashboardRole === "SuperAdmin") && (
              <option value="Trending">TRENDING 🔥 </option>
            )}
          </select>

          {/* <input
        style={{ marginTop: "10px", width: "200px" }}
        type="text"
        placeholder="News Language"
        value={newsLanguage}
        onChange={(e) => setNewsLanguage(e.target.value)}
      /> */}
          {/* <input
        style={{ marginTop: "10px", width: "200px" }}
        type="text"
        placeholder="News State"
        value={newsState}
        onChange={(e) => setNewsState(e.target.value)}
      />
      <input
        style={{ marginTop: "10px", width: "200px" }}
        type="text"
        placeholder="News District"
        value={newsDistrict}
        onChange={(e) => setNewsDistrict(e.target.value)}
      /> */}
          <label>News District Pin</label>

          <input
            // style={{ marginTop: "10px", width: "200px" }}
            type="text"
            placeholder="News District Pin"
            value={newsDistrictPin}
            onChange={(e) => setNewsDistrictPin(e.target.value)}
          />
          {/* <input
        style={{ marginTop: "10px", width: "200px" }}
        type="text"
        placeholder="News Reporter Name"
        value={newsReportername}
        onChange={(e) => setNewsReporterName(e.target.value)}
      /> */}
          <label>Image</label>

          <input
            // style={{ marginTop: "10px" }}
            type="file"
            placeholder="News Image"
            onChange={(e) => setNewsImage(e.target.files[0])}
          />
          <button type="submit" onClick={submitHandler}>
            Submit
          </button>
        </form>
      </div>

    </div>
  );
};
export default Upload;
