import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../Constant";
const BatchRegistration = () => {
    const [isHovered, setIsHovered] = useState(false);

  const [CourseData, setCourseData] = useState();
  //   const [CourseId, setCourseid] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const[userId,setUserId] = useState("");
  const { id } = useParams();

  const logindata = async () => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userInfo = JSON.parse(whichUserSignedIn);

        if (userInfo && userInfo.UserId && userInfo.UserId.Mail) {
          //setuserDashboard(userInfo.UserId.Mail);

          console.log("User's Mail:", userInfo.UserId.Mail);
          console.log("User's Name:", userInfo.UserId.Name);
          console.log("User's Id:", userInfo.UserId._id);

          setUserEmail(userInfo.UserId.Mail);
          setUserName(userInfo.UserId.Name);
          setUserId(userInfo.userId._id)
          //setuserRole(userInfo.UserId.role);
        } else {
          console.log("Mail property not found in UserInfo.");
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("UserInfo not found in localStorage...............");
    }
  };

  const getCourse = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/course/GetCourse`
      );

      //   console.log("The response is", response.data);
      const Course = response.data;
      //   Course.forEach((course) => {
      //     console.log("The Title is", course._id === id ? response.data : null);
      //     setCourseData(course._id === id ? response.data : null);
      //   });

      const matchedCourse = Course.find((course) => course._id === id);
      if (matchedCourse) {
        console.log("The matched course is", matchedCourse);
        setCourseData(matchedCourse);
      }
    } catch (error) {
      console.log("there is error", error);
    }
  };

  const AddStudentToBatch = async (_id) => {
    try {
      const payload = {
        BatchId: _id,
        studentName: userName,
        studentEmail: userEmail,
      };
      await axios.patch(
        `${BASE_URL}/api/course/AddStudent/${id}`,
        payload
      );
      alert("Student added to batch");
      //   setStudentName("");
      //   setStudentEmail("");
    } catch (error) {
      console.log("Student not added ---", error.response || error.message);
    }
  };


  const deleteStudent = async (batchId, _id, name, email) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this student?"
    );
    if (!confirmDelete) return;
  
    try {
      const payload = {
        BatchId: batchId,
        studentName: name,
        studentEmail: email,
      };
  
      await axios.delete(`${BASE_URL}/api/course/deleteStudent/${_id}`, {
        data: payload,
      });
      alert("Student removed from batch");
    } catch (error) {
      console.log(
        "Failed to delete student ---",
        error.response || error.message
      );
      console.log(batchId, _id, name, email);
      alert("Failed to delete student. Please try again.");
    }
  };
  

  useEffect(() => {
    getCourse();
    logindata();
  }, []);

  const hasUserApplied = (batch) => {
    return (
      batch.Students && batch.Students.some((item) => item.email === userEmail)
    );
  };



  return (
    <div style={{ padding: 10 ,fontWeight:'bold' }}>
      <p style={{ fontWeight: "bold" }}> 🏠 Apply for Course</p>
      {CourseData ? (
        <div key={CourseData._id}>
          <div style={{ display: "flex", flexDirection: "row", padding: 10 }}>
            <div
              style={{
                backgroundColor: "white",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                borderRadius: 10,
                width: "100%",
                //  height: "25%",
                padding: 10,
              }}
            >
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                {CourseData.Title}
              </p>
              <p style={{ fontWeight: "bold" }}>Batches : </p>
              {CourseData.CourseBatch &&
                CourseData.CourseBatch.toReversed().map((batch, index) => (
                  <div key={index}>
                    <p style={{ fontWeight: "bold" }}>
                      <strong>{index + 1}.</strong> {batch.BatchTitle}
                    </p>
                    <p>
                      <strong style={{ color: "blue" }}>
                        {batch.BatchDetails} 
                      </strong>
                    </p>
                    {/* <Link to={{ pathname: `/BatchupdateEdit/${batch._id}` }}>
                      Link
                    </Link> */}

                    {/* {batch.Students &&
                      batch.Students.map((item, index) => (
                        <div key={item._id}>
                          {item.email === userEmail ? (
                            <p>Applied</p>
                          ) : (
                            <p>Apply</p>
                          )}
                        </div>
                      ))} */}
                    {/* 
                    <p>
                      {userEmail},{userName} ,<strong> Batch Id : </strong>
                      {batch._id}
                    </p> */}

{/* <p>{userName},{userEmail}</p>*/}
        <p
                      style={{
                        fontWeight: "bold",
                        color: hasUserApplied(batch) ? "Green" : "none",
                      }}
                    >
                      {hasUserApplied(batch) ? "Applied" : null}
                    </p>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: hasUserApplied(batch)
                            ? "grey"
                            : "orange",
                          color: "white",
                          borderRadius: 1,
                          padding: 1,
                          // cursor:'not-allowed',
                          width: "50%",
                          cursor: hasUserApplied(batch)
                            ? "not-allowed"
                            : "pointer",
                        }}
                        onClick={
                          hasUserApplied(batch)
                            ? null
                            : () => AddStudentToBatch(batch._id)
                        }
                      >
                        <p style={{ textAlign: "center" }}>
                          {hasUserApplied(batch) ? "Applied" : "Apply"}
                        </p>
                      </div>

                      <div
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: 1,
                          padding: 1,
                          width: "50%",
                          cursor: "pointer",
                         // backgroundColor: isHovered ? "darkred" : "red", // Change color on hover

                        }}
                        // onMouseEnter={() => setIsHovered(true)}
                        // onMouseLeave={() => setIsHovered(false)}
                        onClick={()=>deleteStudent(batch._id,userId,userName,userEmail)}
                      >
                        <p style={{ textAlign: "center" }}>Remove</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <p>No course data found.</p>
      )}
    </div>
  );
};
export default BatchRegistration;
