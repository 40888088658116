import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constant";
export default function InternshipUpdateEdit() {
  const { id } = useParams();
  const [BatchData, setBatchData] = useState([]);
  const [AddTrainer, SetAddTrainer] = useState(false);
  const [BatchTrainer, SetBatchTrainer] = useState("");
  const [InternshipTrainerName, SetInternshipTrainerName] = useState("");
  const [InternshipTrainerEmail, SetInternshipTrainerEmail] = useState("");

  const [AddStudent, setAddStudent] = useState(false);
  const [StudentName, setStudentName] = useState("");
  const [StudentEmail, setStudentEmail] = useState("");

  const [TrainerList, setTrainersList] = useState([]);
  const [StudentList, SetStudentList] = useState([]);
  const [CollegeList, SetCollegeList] = useState([]);

  const [selectedCollege, SetSelectedCollege] = useState("");
  const [SelectedGender, SetSelectedGender] = useState("");
  const [Qualification, SetQualification] = useState("");
  const [ClassTiming, SetClassTiming] = useState("");

  const [StudentAddMessage, setStudentAddMessage] = useState("");
  

  const GetUsers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/auth/GetUsers`);
      const userData = res.data;
      console.log("list of users", userData);

      const students = userData.filter(
        (item) =>
          item.role === "Student" &&
          item.College === selectedCollege.toString() &&
          item.Gender === SelectedGender.toString() &&
          item.Qualification === Qualification.toString()
        // item.ClassTiming === ClassTiming.toString()
      );
      console.log("Students...", students);
      SetStudentList(students);

      const trainers = userData.filter((item) => item.role === "Trainer");
      setTrainersList(trainers);
      console.log("List of trainers", trainers);

      const college = userData.filter((item) => item.role === "College");
      SetCollegeList(college);
      console.log("College List", college);

    } catch (error) {
      console.error("Unable to fetch data", error);
    }
  };

  const getCourse = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/internship/GetInternship`);

      const courses = response.data;
      

      let matchedBatch = null;
      courses.forEach((course) => {
        const foundBatch = course.Internship.find((batch) => batch._id === id);
        if (foundBatch) {
          matchedBatch = { ...foundBatch }; // Store course title if needed
        }
      });

      if (matchedBatch) {
        console.log("The matched batch details:");
        console.log("Internship Title...................................:", matchedBatch.InternshipTitle); // Log BatchTitle
        console.log("Internship Details:", matchedBatch.InternshipDetails); // Log BatchDetails
        setBatchData(matchedBatch);
      } else {
        console.log("No batch found with the given ID");
      }
    } catch (error) {
      console.log("there is error", error);
    }
  };

  useEffect(() => {
    getCourse();
    GetUsers();
  }, []);

  const Trainerbox = () => {
    SetAddTrainer(true);
  };

  const TrainerBoxClose = () => {
    SetAddTrainer(false);
  };

  const AddTrainertoBatch = async (_id) => {
    try {
      const payload = {
        InternshipTrainerName,
        InternshipTrainerEmail,
        BatchId: _id,
      };

      const response = await axios.patch(
        `${BASE_URL}/api/internship/AddTrainer/${id}`,
        payload
      );

      alert("Trainer is added to Batch");
      console.log("Trainer is added to batch");
      SetInternshipTrainerName("");
      SetInternshipTrainerEmail("");
    } catch (error) {
      console.log("Batch not updated ---", error.response || error.message);
    }
  };

  const Studentbox = () => {
    setAddStudent(true);
  };

  const StudentboxClose = () => {
    setAddStudent(false);
  };

  const AddStudentToBatch = async (_id) => {
    try {
      const payload = {
        BatchId: _id,
        studentName: StudentName,
        studentEmail: StudentEmail,
      };

      await axios.patch(`${BASE_URL}/api/internship/AddStudent/${id}`, payload);
      alert("Student added to batch");
      setStudentName("");
      setStudentEmail("");
    } catch (error) {
      console.log("Student not added ---", error.response || error.message);
      if (error.status === 409) {
        console.log("Student Already added");
        setStudentAddMessage("This Student is Already Added");
      }
    }
  };

  
  const deleteStudent = async (batchId, _id, name, email) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this student?"
    );
    if (!confirmDelete) return;

    try {
      const payload = {
        BatchId: batchId,
        studentName: name,
        studentEmail: email,
      };

      await axios.delete(`${BASE_URL}/api/course/deleteStudent/${_id}`, {
        data: payload,
      });
      alert("Student removed from batch");
    } catch (error) {
      console.log(
        "Failed to delete student ---",
        error.response || error.message
      );
      console.log(batchId, _id, name, email);
      alert("Failed to delete student. Please try again.");
    }
  };

  return (
    <div>
      {BatchData ? (
        <div>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              margin: "20px auto",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Batch Title
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Students List Link
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Batch Details
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Batch Duration
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Batch Trainer
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Add Trainer
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Add Student
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f9f9f9", textAlign: "center" }}>
                <td style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  {BatchData.InternshipTitle}
                </td>
                <td style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  <Link
                    to={`/InternshipStudentList/${BatchData._id}`}
                    className="batch-link"
                  >
                    Link
                  </Link>
                </td>
                <td style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  {BatchData.InternshipDetails}
                </td>
                <td style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  {BatchData.InternshipDuration}
                </td>
                <td style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  {BatchData.InternshipTrainerName}
                </td>
                <td style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#FFA500",
                        borderRadius: "5px",
                        padding: "10px",
                        color: "white",
                        cursor: "pointer",
                        width: "100px",
                        textAlign: "center",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                      }}
                      onClick={Trainerbox}
                    >
                      Add/Update Trainer
                    </div>
                    {AddTrainer && (
                      <div style={{ marginTop: "10px", textAlign: "center" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={TrainerBoxClose}
                        >
                          Close
                        </p>

                        {/* <select
                          name="Trainers"
                          id="Trainers"
                          onChange={(e) => SetInternshipTrainerName(e.target.value)}
                          value={InternshipTrainerName}
                          style={{
                            padding: "8px",
                            borderRadius: "5px",
                            border: "1px solid #ddd",
                            width: "100%",
                          }}
                        >
                          <option value="selectrainer">Select a trainer</option>
                          {TrainerList.map((item, index) => (
                            <option value={item.Name}>
                              {item.Name} - {item.Mail}
                            </option>
                          ))}
                        </select> */}


                        <select
  name="Trainers"
  id="Trainers"
  onChange={(e) => {
    const selectedTrainer = TrainerList.find(
      (trainer) => trainer.Name === e.target.value
    );
    SetInternshipTrainerName(selectedTrainer.Name); // Set trainer name
    SetInternshipTrainerEmail(selectedTrainer.Mail); // Set trainer email
  }}
  value={InternshipTrainerName || "selectrainer"}
  style={{
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    width: "100%",
  }}
>
  <option value="selectrainer">Select a trainer</option>
  {TrainerList.map((item, index) => (
    <option key={index} value={item.Name}>
      {item.Name} - {item.Mail}
    </option>
  ))}
</select>




                        <div
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            borderRadius: "5px",
                            padding: "8px",
                            marginTop: "10px",
                            cursor: "pointer",
                            width: "100px",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                          }}
                          onClick={() => AddTrainertoBatch(BatchData._id)}
                        >
                          Submit
                        </div>
                      </div>
                    )}
                  </div>
                </td>
                <td style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  <div
                    style={{
                      backgroundColor: "#007bff",
                      borderRadius: "5px",
                      padding: "10px",
                      color: "white",
                      cursor: "pointer",
                      width: "100px",
                      textAlign: "center",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={Studentbox}
                  >
                    Add Student
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {AddStudent && (
            <div
              style={{
                marginTop: "10px",
                textAlign: "center",
                margin: "30px",
                backgroundColor: "white",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={StudentboxClose}
              >
                CLOSE
              </p>

              <div
                style={{
                  //display: "flex",
                  //flexDirection: "row",
                  // gap: "50px",
                  //alignItems: "center",
                  // justifyContent: "center",
                  //  backgroundColor:'white',
                  //  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"

                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems:'center'
                  //  width:'50%'

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                  //  backgroundColor: "black",
                  //  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  gap: "50px",
                  borderRadius: "10px",
                  marginRight: "auto",
                  marginLeft: "auto",
                  width: "80%",
                  borderWidth: "2px",
                }}
              >
                <p
                  style={{
                    backgroundColor: "white",
                    padding: "5px",
                    borderRadius: "10px",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Filter
                </p>

                {CollegeList.map((item, index) => (
                  <select
                    style={{
                      borderRadius: "10px",
                      width: "8%",
                      height: "30px",
                      borderWidth: "2px",
                    }}
                    id="SelectedCollege"
                    name="SelectedCollege"
                    onChange={(e) => SetSelectedCollege(e.target.value)}
                    onClick={GetUsers}
                  >
                    <option value="none">College</option>

                    <option value="xyz">xyz</option>
                    <option value="abc">abc</option>
                    <option value={item.Name}>{item.Name}</option>
                  </select>
                ))}

                <select
                  style={{
                    borderRadius: "10px",
                    width: "8%",
                    height: "30px",
                    borderWidth: "2px",
                  }}
                  onChange={(e) => SetSelectedGender(e.target.value)}
                  onClick={GetUsers}
                >
                  <option value="none">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>

                <select
                  style={{
                    borderRadius: "10px",
                    width: "8%",
                    height: "30px",
                    borderWidth: "2px",
                  }}
                  name="EducationQualification"
                  id="EducationQualification"
                  onChange={(e) => SetQualification(e.target.value)}
                  onClick={GetUsers}
                >
                  <option value="none">Qualfication</option>
                  <option value="10plus">10 +</option>
                  <option value="12plus">12 +</option>
                  <option value="12plus">DIPLOMA</option>

                  <option value="BA">BA</option>
                  <option value="BSC">BSC</option>
                  <option value="BCA">BCA</option>
                  <option value="BCOM">BCOM</option>
                  <option value="BTECH">BTECH</option>

                  <option value="Other-UG">---Other UG---</option>

                  <option value="MA">MA</option>
                  <option value="MSC">MSC</option>
                  <option value="MCA">MCA</option>
                  <option value="MTECH">MTECH</option>
                  <option value="MCOM">MCOM</option>

                  <option value="Other_PG">---Other PG---</option>

                  <option value="PHD">PHD</option>
                  <option value="OTHER">OTHER</option>
                </select>

                {/* 
                        <select
                name="Preferred-Time-For-Class"
                id="Preferred-Time-For-Class"
                onChange={(e) => SetClassTiming(e.target.value)}
                onClick={GetUsers}
              >
                <option value="none">Timing</option>
                <option value="Morning">Morning</option>
                <option value="Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
              </select> */}

                {/* <p>Filter</p> */}
              </div>

              <div
                style={{
                  //display: "block",
                  //flexDirection: "column",
                  //justifyContent: "center",
                  //alignContent:'center'
                  //textAlign:'center'
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <select
                  name="Student"
                  id="Student"
                  // onChange={(e) => setStudentEmail(e.target.value)}
                  onChange={(e) => {
                    const selectedStudent = StudentList.find(
                      (item) => item.Mail === e.target.value
                    );
                    setStudentEmail(selectedStudent?.Mail || "");
                    setStudentName(selectedStudent?.Name || "");
                  }}
                  value={StudentEmail}
                  style={{
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    width: "61%",
                  }}
                >
                  <option value="Select Student" disabled>
                    Select Students
                  </option>
                  {StudentList.map((item) => (
                    <option key={item._id} value={item.Mail}>
                      {item.Mail} - {item.Name}
                      {/* {setStudentName(item.Name)} */}
                    </option>
                  ))}
                </select>

                <input
                  type="text"
                  placeholder="Student Name"
                  value={StudentName}
                  onChange={(e) => setStudentName(e.target.value)}
                  readOnly
                  style={{
                    padding: "8px",
                    margin: "5px 0",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    width: "60%",
                    backgroundColor: "whitesmoke",
                  }}
                />

                <input
                  type="email"
                  placeholder="Student Email "
                  value={StudentEmail}
                  onChange={(e) => setStudentEmail(e.target.value)}
                  readOnly
                  style={{
                    padding: "8px",
                    margin: "5px 0",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    width: "60%",
                    backgroundColor: "whitesmoke",
                  }}
                />
                <p
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "5px",
                    padding: "8px",
                    // marginTop: "10px",
                    cursor: "pointer",
                    width: "50%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                    // textAlign:'center'
                  }}
                  onClick={() => AddStudentToBatch(BatchData._id)}
                >
                  Submit
                </p>
                <p style={{ color: "red" }}>{StudentAddMessage}</p>
              </div>
            </div>
          )}

         
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
}
