import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Constant";
import { Link } from "react-router-dom";

export default function InternshipCohortBatches() {
  const [userMail, setUserMail] = useState("");
  const [userRole, setuserRole] = useState("");
  const [StudentBatchList, SetStudentBatch] = useState([]);
  const [LectureBox, SetLectureBox] = useState(null);
  const [openSpecificlec, SetopenSpecificlec] = useState(null);

  const LoggedUser = () => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userInfo = JSON.parse(whichUserSignedIn);
        if (userInfo && userInfo.UserId && userInfo.UserId.Mail) {
          setUserMail(userInfo.UserId.Mail);
          setuserRole(userInfo.UserId.role);
        } else {
          console.log("Mail property not found in UserInfo.");
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("UserInfo not found in localStorage.");
    }
  };

  const getCourse = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/internship/GetInternship`
      );
      SetStudentBatch(response.data);
    } catch (error) {
      console.log("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    LoggedUser();
  }, []);

  useEffect(() => {
    if (userMail) {
      getCourse();
    }
  }, [userMail]);

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "center",
        // backgroundColor:'black'
      }}
    >
      {StudentBatchList.map((item) =>
        item.Internship.map((batch) =>
          batch.Students.map((stu) =>
            stu.email === userMail ? (
              <div
                key={stu._id}
                style={{
                  backgroundColor: "#ffffff",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                  minWidth: "300px",
                  width: "100%",
                  padding: "15px",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                // onMouseEnter={(e) => {
                //   e.currentTarget.style.transform = "scale(1.03)";
                //   e.currentTarget.style.boxShadow = "0 6px 20px rgba(0, 0, 0, 0.2)";
                // }}
                // onMouseLeave={(e) => {
                //   e.currentTarget.style.transform = "scale(1)";
                //   e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                // }}
              >
                {stu.isVerified === true ? (
                  <p style={{ color: "green", fontWeight: "bold" }}>Active</p>
                ) : (
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Not Activated
                  </p>
                )}

                <h3
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {batch.InternshipTitle.toUpperCase()}
                </h3>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#555",
                    marginBottom: "10px",
                  }}
                >
                  {batch.InternshipDetails}
                </p>
                {stu.isVerified === true ? (
                  <button
                    onClick={() => SetLectureBox(batch._id)}
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    View Lectures
                  </button>
                ) : null}
                {LectureBox === batch._id && (
                  <div style={{ marginTop: "10px" }}>
                    <p
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => SetLectureBox(null)}
                    >
                      Close Lectures
                    </p>

                    {batch.lectures.map((apnaLec) => (
                      <div
                        key={apnaLec._id}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "10px",
                          marginBottom: "10px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                          onClick={() => SetopenSpecificlec(apnaLec._id)}
                        >
                          {apnaLec.title}
                        </p>

                        {openSpecificlec === apnaLec._id && (
                          <div>
                            <p
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                textAlign: "right",
                                cursor: "pointer",
                              }}
                              onClick={() => SetopenSpecificlec(null)}
                            >
                              Close Details
                            </p>

                            <p>
                              <strong>Lecture No:</strong> {apnaLec.title}
                            </p>
                            <p>
                              <strong>Link:</strong>{" "}
                              <Link to={apnaLec.link}>{apnaLec.link}</Link>
                            </p>
                            <p>
                              <strong>Details:</strong> {apnaLec.details}
                            </p>
                            <p>
                              <strong>Duration:</strong> {apnaLec.duration}
                            </p>
                            {/* <img style={{height:'50px',width:'50px'}} src={`${BASE_URL}/${apnaLec.lecturePdf}`} /> */}

                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : null
          )
        )
      )}
    </div>
  );
}
