import React, { useState } from "react";
import "../../Assets/Css/Upload.css";
import axios from "axios";
import { BASE_URL } from "../../Constant";

export default function CreateCourse() {
  const [CourseTitle, setCourseTitle] = useState("");
  const [CourseDetails, setCourseDetails] = useState("");
  const [CourseLanguage, setCourseLanguage] = useState("");
  const [CourseDuration, SetCourseDuration] = useState("");
  const [CourseMode, SetCourseMode] = useState("");
  const [CourseType, SetCourseType] = useState("");
  const [CoursePrice, SetCoursePrice] = useState("");
  const [CourseImage, SetCourseImage] = useState("");


  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("Title", CourseTitle);
    formData.append("Details", CourseDetails);
    formData.append("Language", CourseLanguage);
    formData.append("Duration", CourseDuration);
    formData.append("Mode", CourseMode);
    formData.append("Type", CourseType);
    formData.append("Price", CoursePrice);
    formData.append("Image", CourseImage);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/course/create`,
        formData,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      alert("Your course is uploaded");
      console.log("course uploaded successfully", response.data);
      setCourseTitle('');
      setCourseDetails('');
      setCourseLanguage('');
      SetCourseDuration('');
      SetCourseMode('');
      SetCourseType('');
      SetCoursePrice('');
      SetCourseImage('');
    } catch (error) {
      console.log("course not uploaded ---", error.response || error.message);
    }
  };


  return (
    <div
      style={{
        marginTop: "10px",
        backgroundColor: "white",
        padding: "10px",
        color: "black",
        boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="box">
        <form className="form-group">
          <h1 style={{ textAlign: "center" }}>Create Course</h1>

          <label>Course Title</label>
          <input
            type="text"
            placeholder="Course title"
            value={CourseTitle}
            onChange={(e) => setCourseTitle(e.target.value)}
          />

          <label>Course Details</label>
          <textarea
            type="text"
            placeholder="Write the short summary of the course here...."
            value={CourseDetails}
            onChange={(e) => setCourseDetails(e.target.value)}
          />

          <label>Course Duration</label>
          <input
            type="text"
            placeholder="Course duration in days"
            value={CourseDuration}
            onChange={(e) => SetCourseDuration(e.target.value)}
          />

          <label>Course Price</label>
          <input
            type="text"
            placeholder="Course Price"
            value={CoursePrice}
            onChange={(e) => SetCoursePrice(e.target.value)}
          />

          <label>Course Mode</label>

          <select
            id="CourseMode"
            name="CourseMode"
            value={CourseMode}
            onChange={(e) => SetCourseMode(e.target.value)}
          >
            <option value="None">Online</option>
            <option value="Gen Ai">Offline</option>
            <option value="Programming">Hybrid</option>
          </select>

          <label>Course Language</label>

          <select
            id="CourseLanguage"
            name="CourseLanguage"
            value={CourseLanguage}
            onChange={(e) => setCourseLanguage(e.target.value)}
          >
            <option value="None">Language</option>
            <option value="Hindi">Hindi</option>
            <option value="Enlgish">Enlgish</option>
            <option value="Bengali">Bengali </option>
            <option value="Marathi">Marathi </option>
            <option value="Telugu">Telugu </option>
            <option value="Tamil">Tamil </option>
            <option value="Gujarati">Gujarati </option>
            <option value="Kannada">Kannada </option>
            <option value="Odia">Odia </option>
            <option value="Malayalam">Malayalam </option>
            <option value="Punjabi">Punjabi </option>
            <option value="Maithili">Maithili </option>
            <option value="Santali">Santali </option>
            <option value="Kashmiri">Kashmiri </option>
            <option value="Nepali">Nepali </option>
            <option value="Konkani">Konkani </option>
            <option value="Sindhi">Sindhi </option>
            <option value="Manipuri Meitei">Manipuri Meitei </option>
            <option value="Bodo">Bodo </option>
            <option value="Sanskrit">Sanskrit </option>
          </select>

          <label>Course Type</label>

          <select
            id="CourseType"
            name="CourseType"
            value={CourseType}
            onChange={(e) => SetCourseType(e.target.value)}
          >
            <option value="None">Course Type</option>
            <option value="Gen Ai">Gen Ai</option>
            <option value="Programming">Programming</option>
            <option value="Finance">Finance</option>
            <option value="DigitalMarketing">Digital Marketing</option>
            <option value="BusinessOperations">Business Operations</option>
            <option value="VideoEditing">Video Editing</option>
          </select>

          <label>Image</label>

          <input
            type="file"
            placeholder="Course Image"
            onChange={(e) => SetCourseImage(e.target.files[0])}
          />
          <button type="submit" onClick={submitHandler}>
        Submit
      </button>
        </form>
      </div>
    </div>
  );
}
