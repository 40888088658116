import React from 'react';
import './ServiceCard.css';

export const ServiceCard = ({ title, description, bgColor }) => {
  return (
    <div className="service-card" style={{ backgroundColor: bgColor }}>
      <h3>{title}</h3>
      <p>{description}</p>
      <button className="service-btn">Learn More</button>
    </div>
  );
}
