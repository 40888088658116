import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Constant";

export default function CreateInternshipCohort() {
  const [CourseData, setCourseData] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [batchForms, setBatchForms] = useState({});

  const getInternship = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/internship/GetInternship`);
      console.log("The response is", response.data);
      setCourseData(response.data);
    } catch (error) {
      console.log("there is error", error);
    }
  };

  useEffect(() => {
    getInternship();
  }, []);

  const submitHandler = async (_id) => {
    try {
      const { InternshipTitle, InternshipDetails, InternshipDuration } = batchForms[_id] || {};
      const payload = { InternshipTitle, InternshipDetails, InternshipDuration };

      const response = await axios.patch(
        `${BASE_URL}/api/internship/CreateInternshipCohort/${_id}`,
        payload
      );

      alert("Internship cohort is created successfully");
      console.log("Cohort uploaded successfully", response.data);

      setBatchForms((prevForms) => ({
        ...prevForms,
        [_id]: { InternshipTitle: "", InternshipDetails: "", InternshipDuration: "" },
      }));
      setSelectedCourseId(null);
    } catch (error) {
      console.log("Cohort not uploaded ---", error.response || error.message);
    }
  };

  const handleInputChange = (_id, field, value) => {
    setBatchForms((prevForms) => ({
      ...prevForms,
      [_id]: { ...prevForms[_id], [field]: value },
    }));
  };

  return (
    <div style={styles.container}>
      <h1
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: "white",
          padding: "20px",
          backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)",
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
          margin: "20px auto",
          width: "50%",
        }}
      >
        Create Internhsip Cohorts
      </h1>

      <div style={styles.cardContainer}>
        {CourseData.map((item) => (
          <div key={item._id} style={styles.card}>
            <p style={styles.courseTitle}>{item.CompanyTitle}</p>

            <div style={styles.courseDetails}>
              <ul>
                <li>{item.CompanyDetails}</li>
                {/* <li>{item.Mode}</li> */}
                <li>{item.Language}</li>
              </ul>
            </div>

            <div
              style={styles.addButton}
              onClick={() => setSelectedCourseId(item._id)}
            >
              <p style={styles.addButtonText}>Add a Cohort</p>
            </div>






            {selectedCourseId === item._id && (
              <div style={styles.formContainer}>
                <h3 style={styles.formTitle}>Create a Cohort</h3>
                <p
                  onClick={() => setSelectedCourseId(null)}
                  style={styles.closeButton}
                >
                  Close
                </p>
                <div style={styles.form}>
                  <label style={styles.label}>Internship Title:</label>
                  <input
                    type="text"
                    placeholder="Internship Title"
                    value={batchForms[item._id]?.InternshipTitle || ""}
                    onChange={(e) =>
                      handleInputChange(item._id, "InternshipTitle", e.target.value)
                    }
                    style={styles.input}
                  />

                  <label style={styles.label}>Internship Details:</label>
                  <input
                    type="text"
                    placeholder="Internship Details"
                    value={batchForms[item._id]?.InternshipDetails || ""}
                    onChange={(e) =>
                      handleInputChange(
                        item._id,
                        "InternshipDetails",
                        e.target.value
                      )
                    }
                    style={styles.input}
                  />

                  <label style={styles.label}>Internship Duration:</label>

                  <input
                    type="text"
                    placeholder="Internship Duration"
                    value={batchForms[item._id]?.InternshipDuration || ""}
                    onChange={(e) =>
                      handleInputChange(
                        item._id,
                        "InternshipDuration",
                        e.target.value
                      )
                    }
                    style={styles.input}
                  />

                  <div
                    onClick={() => submitHandler(item._id)}
                    style={styles.submitButton}
                  >
                    <p style={styles.submitButtonText}>Submit</p>
                  </div>
                </div>
              </div>
            )}






            <Link to={{ pathname: `/InternshipCohort/${item._id}` }} style={styles.link}>
              <img
                src={`${BASE_URL}/${item.Image}`}
                style={styles.image}
                alt="Course"
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f7f7f7",
  },
  title: {
    textAlign: "center",
    fontSize: "36px",
    fontWeight: "bold",
    color: "#333",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  },
  card: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "20px",
    width: "300px",
    textAlign: "center",
  },
  courseTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#222",
  },
  courseDetails: {
    marginTop: "10px",
    textAlign: "left",
  },
  addButton: {
    marginTop: "10px",
    backgroundColor: "#FFA500",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  addButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "16px",
  },
  formContainer: {
    marginTop: "15px",
    borderRadius: "8px",
    backgroundColor: "#f1f1f1",
    padding: "15px",
  },
  formTitle: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  closeButton: {
    color: "red",
    textAlign: "right",
    fontWeight: "bold",
    cursor: "pointer",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: "#333",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    marginBottom: "10px",
  },
  submitButton: {
    backgroundColor: "#007bff",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  submitButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "180px",
    borderRadius: "8px",
    marginTop: "10px",
  },
};
