import axios from "axios";
import React, { useEffect, useState } from "react";

export default function ApproveNews() {
  const [NewsData, setNewsData] = useState([]);
  const [showmodel, setshowmodel] = useState(false);
  const [user, setuser] = useState("");
  const [adminlist, setadminlist] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editNews, setEditNews] = useState({});
  useEffect(() => {
    const fetchnews = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/api/UploadNews/getallnews`
        );
        console.log("the response is ", response.data);
        setNewsData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchnews();
  }, []);

  useEffect(() => {
    const fetchAdmindata = async () => {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/SubAdmin/getAllAdminlist"
        );
        console.log("this is the response", response);
        setadminlist(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAdmindata();
  }, []);

  useEffect(() => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userinfo = JSON.parse(whichUserSignedIn); // Parse only if valid
        setuser(userinfo);
        console.log("The user is...............", userinfo.AdminName); // Access the AdminName property
      } catch (error) {
        console.error("Error parsing user info:", error);
      }
    }
  }, []);

//   const filteredNews = NewsData.filter((item) => {
//     if (user.role === "SuperAdmin") {
//       return item.role === "Reporter"; // SuperAdmin sees all reporters
//     } else {
//       // SubAdmin sees only reporters in their own state and district
//       return (
//         item.NewsState === user.AdminState &&
//         item.NewsDistrict === user.AdminDistrict
//       );
//     }
//   });

  const filteredNews = NewsData.filter((item) => {
    // SuperAdmin should see all news
    if (user.role === "SuperAdmin") {
      return true; // No filtering for SuperAdmin
    } else {
      // SubAdmin sees only reporters in their own state and district
      return (
        item.NewsState === user.AdminState &&
        item.NewsDistrict === user.AdminDistrict
      );
    }
  });


  const NewsApproval = async (_id) => {
    try {
      const response = await axios.patch(
        `http://localhost:5000/api/UploadNews/approvenews/${_id}`,
        { NewsPause: true }
      );
      alert("News is now Verified");
      // navigate("/ReporterApproval")
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const NewsDisapprove = async (_id) => {
    try {
      const response = await axios.patch(
        `http://localhost:5000/api/UploadNews/disapprovenews/${_id}`,
        { NewsPause: false }
      );
      alert("News is now paused");
      // navigate("/ReporterApproval")
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };



const handleEditClick = (newsItem) => {
  setEditNews(newsItem);
  setShowEditModal(true);
};

 const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditNews((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `http://localhost:5000/api/UploadNews/editnews/${editNews._id}`,
        editNews
      );
      alert("News is updated successfully.");
      setShowEditModal(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const newscount = NewsData.length;

  // const fiveDaysAgo = new Date();
  // fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 1);

  // // Filter news data created 5 days ago
  // const newsCount7DaysAgo = NewsData.filter((item) => {
  //   const newsDate = new Date(item.createdAt); // Replace 'createdAt' with the actual property name
  //   return newsDate.toDateString() === fiveDaysAgo.toDateString();
  // }).length;

  
  // localStorage.setItem("Totalnews",newscount)
  // localStorage.setItem("LastweekTotalnews",newsCount7DaysAgo)

  return (
    <div
      // style={{
      //   marginTop: "10px",
      //   marginRight: "100px",
      //   marginLeft: "100px",
      //   backgroundColor: "white",
      //   padding: "10px",
      //   color: "black",
      //   boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
      // }}
    >
      <h1 style={{ textAlign: "center" }}> Approve News</h1>
      <table
        style={{
          width: "100%",
          // borderCollapse: "collapse",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "5px",
              margin: "10px",
              // position: "sticky",
              borderRadius: "10px",
              backgroundColor: "#f9f9f9",
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
            }}
          >
            <th style={{ padding: "10px" }}>Approval {newscount} </th>

            <th style={{ padding: "10px" }}>Title</th>
            <th style={{ padding: "10px" }}>Reporter</th>
            <th style={{ padding: "10px" }}>Reporter Mail</th>
            <th style={{ padding: "10px" }}>Reference</th>
            <th style={{ padding: "10px" }}>Details</th>
            <th style={{ padding: "10px" }}>Type</th>
            <th style={{ padding: "10px" }}>State</th>

            <th style={{ padding: "10px" }}>Img</th>
          </tr>
        </thead>
        <tbody>
          {filteredNews.toReversed().map((item) => (
            <tr  key={item._id}>
              <td style={{ padding: "10px" }}>
                {item.NewsPause === false ? (
                  <button
                    onClick={() => NewsApproval(item._id)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // padding: "10px 20px",
                      borderRadius: "10px",
                      border: "2px solid lightblue",
                      backgroundColor: "#f0f8ff",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                  >
                    <p
                      style={{
                        color: "lightblue",
                        fontWeight: "bold",
                        margin: 0,
                        transition: "color 0.3s ease",
                      }}
                    >
                      Disapproved ✖ click to approve
                    </p>
                  </button>
                ) : (
                  <button
                     onClick={() => NewsDisapprove(item._id)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // padding: "10px 20px",
                      borderRadius: "10px",
                      border: "2px solid lightgreen",
                      backgroundColor: "#f0fff0",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                  >
                    <p
                      style={{
                        color: "lightgreen",
                        fontWeight: "bold",
                        margin: 0,
                        transition: "color 0.3s ease",
                      }}
                    >
                      Approved ✔ click to disapprove
                    </p>
                  </button>
                )}
              </td>
              <td style={{ color: "black",fontWeight:'bold' }}>{item.NewsTitle}</td>
              <td >{item.NewsReporterName}</td>
              <td >{item.NewsReporterMail}</td>
              <td >{item.NewsReference}</td>
              {/* <p onClick={() => setshowmodel(true)}>Click to read new</p> */}
              {/* {showmodel && ( */}
              <td style={{ color: "blue" }}>{item.NewsDetails}</td>
              <td>{item.NewsType}</td>
              <td>{item.NewsState}</td>

              {/* )} */}
              <td>
                <img
                  style={{ width: "100px", height: "auto" }}
                  src={`http://localhost:5000/${item.NewsImage}`}
                ></img>
              </td>
{/* <td>
              <button onClick={() => handleEditClick(item)}>Edit</button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="edit-modal">
          <form onSubmit={handleEditSubmit}>
            <h2>Edit News</h2>
            <label>Title:</label>
            <input
              type="text"
              name="NewsTitle"
              value={editNews.NewsTitle}
              onChange={handleEditChange}
            />
            <label>Details:</label>
            <textarea
              name="NewsDetails"
              value={editNews.NewsDetails}
              onChange={handleEditChange}
            />
            <button type="submit">Save Changes</button>
            <button onClick={() => setShowEditModal(false)}>Cancel</button>
          </form>
        </div>
      )}


    </div>
  );
}

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import "../../Assets/Css/ApproveNews.css"
// export default function ApproveNews() {
//   const [NewsData, setNewsData] = useState([]);
//   const [showmodel, setshowmodel] = useState(false);
//   const [user, setuser] = useState("");
//   const [adminlist, setadminlist] = useState([]);

//   useEffect(() => {
//     const fetchnews = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:5000/api/UploadNews/getallnews`
//         );
//         setNewsData(response.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchnews();
//   }, []);

//   useEffect(() => {
//     const fetchAdmindata = async () => {
//       try {
//         const response = await axios.get(
//           "http://localhost:5000/api/SubAdmin/getAllAdminlist"
//         );
//         setadminlist(response.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     fetchAdmindata();
//   }, []);

//   useEffect(() => {
//     const whichUserSignedIn = localStorage.getItem("UserInfo");
//     if (whichUserSignedIn) {
//       try {
//         const userinfo = JSON.parse(whichUserSignedIn);
//         setuser(userinfo);
//       } catch (error) {
//         console.error("Error parsing user info:", error);
//       }
//     }
//   }, []);

//   const filteredNews = NewsData.filter((item) => {
//     if (user.role === "SuperAdmin") {
//       return true;
//     } else {
//       return (
//         item.NewsState === user.AdminState &&
//         item.NewsDistrict === user.AdminDistrict
//       );
//     }
//   });

//   const NewsApproval = async (_id) => {
//     try {
//       await axios.patch(
//         `http://localhost:5000/api/UploadNews/approvenews/${_id}`,
//         { NewsPause: true }
//       );
//       alert("News is now Verified");
//       window.location.reload();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const NewsDisapprove = async (_id) => {
//     try {
//       await axios.patch(
//         `http://localhost:5000/api/UploadNews/disapprovenews/${_id}`,
//         { NewsPause: false }
//       );
//       alert("News is now paused");
//       window.location.reload();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div className="approve-news-container">
//       <h1 className="title">Approve News</h1>
//       <table className="news-table">
//         <thead>
//           <tr className="table-header">
//             <th>Approval</th>
//             <th>Title</th>
//             <th>Reporter</th>
//             <th>Reporter Mail</th>
//             <th>Reference</th>
//             <th>Details</th>
//             <th>Type</th>
//             <th>State</th>
//             <th>Img</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredNews.reverse().map((item) => (
//             <tr key={item._id}>
//               <td>
//                 {item.NewsPause === false ? (
//                   <button
//                     onClick={() => NewsApproval(item._id)}
//                     className="approve-btn disapproved"
//                   >
//                     Disapproved ✖ click to approve
//                   </button>
//                 ) : (
//                   <button
//                     onClick={() => NewsDisapprove(item._id)}
//                     className="approve-btn approved"
//                   >
//                     Approved ✔ click to disapprove
//                   </button>
//                 )}
//               </td>
//               <td>{item.NewsTitle}</td>
//               <td>{item.NewsReporterName}</td>
//               <td>{item.NewsReporterMail}</td>
//               <td>{item.NewsReference}</td>
//               <td>{item.NewsDetails}</td>
//               <td>{item.NewsType}</td>
//               <td>{item.NewsState}</td>
//               <td>
//                 <img
//                   className="news-image"
//                   src={`http://localhost:5000/${item.NewsImage}`}
//                   alt="news"
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }
