import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Constant";
export default function StudentsList() {
  const { id } = useParams();
  const [BatchData, setBatchData] = useState([]);
  const [StudentList, SetStudentList] = useState([]);
  const [TrainersList, setTrainersList] = useState([]);

  const GetUsers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/auth/GetUsers`);
      const userData = res.data;
      console.log("list of users", userData);

      const students = userData.filter((item) => item.role === "Student");
      SetStudentList(students);

      const trainers = userData.filter((item) => item.role === "Trainer");
      setTrainersList(trainers);
      console.log("List of trainers", trainers);
    } catch (error) {
      console.error("Unable to fetch data", error);
    }
  };

  const getCourse = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/course/GetCourse`);

      const courses = response.data;

      let matchedBatch = null;
      courses.forEach((course) => {
        const foundBatch = course.CourseBatch.find((batch) => batch._id === id);
        if (foundBatch) {
          matchedBatch = { ...foundBatch }; // Store course title if needed
        }
      });

      if (matchedBatch) {
        console.log("The matched batch details:");
        console.log("Batch Title:", matchedBatch.BatchTitle); // Log BatchTitle
        console.log("Batch Details:", matchedBatch.BatchDetails); // Log BatchDetails
        setBatchData(matchedBatch);
      } else {
        console.log("No batch found with the given ID");
      }
    } catch (error) {
      console.log("there is error", error);
    }
  };

  useEffect(() => {
    getCourse();
    GetUsers();
  }, []);

  const deleteStudent = async (batchId, _id, email, name) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this student?"
    );
    if (!confirmDelete) return;

    try {
      const payload = {
        BatchId: batchId,
        studentName: name,
        studentEmail: email,
      };
      console.log("Batch id", batchId, _id);
      await axios.delete(`${BASE_URL}/api/course/deleteStudent/${_id}`, {
        data: payload,
      });
      alert("Student removed from batch");
    } catch (error) {
      console.log(
        "Failed to delete student ---",
        error.response || error.message
      );
      console.log(batchId, _id, name, email);
      alert("Failed to delete student. Please try again.");
    }
  };

  const verifiedStatus = async (batchId, _id, email) => {
    try {
      console.log("here is the id",batchId, _id,email );

      const payload = {
        BatchId: batchId,
        studentEmail:email,
        // isVerified:true,
      };

      const response = await axios.patch(
        `${BASE_URL}/api/course/studentVerified/${_id}`,
        payload
      );
      // console.log("here is the response", response);
      alert("Verified")
    } catch (error) {
      console.log("Here is the error", error);
    }
  };

  return (
    <div>
      {/* <h3
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: "white",
          padding: "10px",
          backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)",
          //backgroundColor:'black',
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
          margin: "10px auto",
          width: "30%",
        }}
      >
        Batch Operations
      </h3>     */}

      {BatchData ? (
        <div>
          <div
            style={{
              backgroundColor: "white",
              minHeight: "100vh",
              width: "100%",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
              fontFamily: "monospace",
              fontSize: "18px",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "2rem",
                fontWeight: "bold",
                fontFamily: "monospace",
                color: "white",
                padding: "5px",
                backgroundImage: "linear-gradient(to right, #da22ff, #9733ee)", //backgroundColor:'black',
                borderRadius: "10px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                margin: "10px auto",
                width: "30%",
              }}
            >
              ꜱᴛᴜᴅᴇɴᴛꜱ ʟɪꜱᴛ
            </p>

            <table style={{ width: "100%" }}>
              <tr
                style={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Student Name
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Student Email
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Verified Status
                </th>
                <th style={{ padding: "15px", borderBottom: "1px solid #ddd" }}>
                  Delete Record
                </th>
              </tr>

              {BatchData.Students &&
                BatchData.Students.map((item, index) => (
                  // <div key={item._id}>
                  //   <p>{item.name}</p>
                  // </div>
                  <tr key={item._id}>
                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {item.name}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {item.email}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {item.isVerified.toString()}

                      <p
                        style={{
                          backgroundColor: "black",
                          padding: "1px",
                          color: "white",
                          cursor: "pointer",
                          borderRadius: "10px",
                        }}
                        onClick={() =>
                          verifiedStatus(BatchData._id, item._id, item.email)
                        }
                      >
                        Click to Verify
                        {item._id}
                      </p>
                    </td>

                    <td
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                        border: "1px solid #ddd",
                        padding: "10px",
                      }}
                      // onClick={() => deleteStudent(item._id)}
                      onClick={() =>
                        deleteStudent(
                          BatchData._id,
                          item._id,
                          item.name,
                          item.email
                        )
                      }
                    >
                      Delete
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
}
