import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Constant";

export default function StudentCredentials() {
  const [StudentList, SetStudentList] = useState([]);
  const [TrainersList, setTrainersList] = useState([]);
  const [isVisible, setIsVisible] = useState(false); // Visibility state
  const [studentModel, setStudentModel] = useState(false);
  const [trainerModel, setTrainerModel] = useState(false);

  const GetUsers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/api/auth/GetUsers`);
      const userData = res.data;
      console.log("list of users", userData);

      const students = userData.filter((item) => item.role === "Student");
      SetStudentList(students);

        const trainers = userData.filter((item) => item.role === "Trainer");
        setTrainersList(trainers);
      //  console.log("List of trainers", trainers);
    } catch (error) {
      console.error("Unable to fetch data", error);
    }
  };

  useEffect(() => {
    GetUsers();
  }, []);


  return (
    <div>
      <div
        onClick={() => setStudentModel(true)}
        style={{
          margin: "1px",
          padding: "20px",
          fontFamily: "Arial, sans-serif",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "monospace",
            fontWeight: "bold",
            cursor:'pointer' 
          }}
        >
          Student Credentials ⬇
        </h2>
        {studentModel && (
          <div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setStudentModel(!studentModel);
              }}
            >
              <p style={{ color: "red",fontWeight:'bold',textAlign:'right' ,cursor:'pointer' }}>Close</p>
            </div>

            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                margin: "0 auto",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    #
                  </th>
                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Email
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Number
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    College
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Qualfication
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    InterestedCourse
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Gender
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    DOB
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Class Timing
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Verified
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Address
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Aadhar Number
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Password
                  </th>
                </tr>
              </thead>
              <tbody>
                {StudentList.map((student, index) => (
                  <tr
                    key={student._id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                    }}
                  >
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.Name}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.Mail}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.Number}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.College}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.Qualification}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.InterestedCourse}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.Gender}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.DateOfBirth}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.ClassTiming}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.verified.toString()}
                    </td>

                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.DetailAddress}
                    </td>

                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {student.AadharNumber}
                    </td>

                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      <p onClick={() => setIsVisible(!isVisible)}>Show</p>
                      {isVisible && student.Password}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div
        onClick={() => setTrainerModel(true)}
        style={{
          margin: "1px",
          padding: "20px",
          fontFamily: "Arial, sans-serif",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            marginBottom: "20px",
            fontFamily: "monospace",
            fontWeight: "bold",
            cursor:'pointer' 
          }}
        >
          Trainer Credentials ⬇
        </h2>

        {trainerModel && (
          <div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setTrainerModel(!trainerModel);
              }}
            >
              <p style={{ color: "red",fontWeight:'bold',textAlign:'right',cursor:'pointer' }}>Close</p>
              </div>

            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                margin: "0 auto",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    #
                  </th>
                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Name
                  </th>
                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Email
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Number
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    College
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Qualfication
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    InterestedCourse
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Gender
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    DOB
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Class Timing
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Verified
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Address
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Aadhar Number
                  </th>

                  <th
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    Password
                  </th>
                </tr>
              </thead>
              <tbody>
                {TrainersList.map((trainer, index) => (
                  <tr
                    key={trainer._id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                    }}
                  >
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.Name}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.Mail}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.Number}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.College}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.Qualification}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.InterestedCourse}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.Gender}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.DateOfBirth}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.ClassTiming}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.verified.toString()}
                    </td>

                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.DetailAddress}
                    </td>

                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      {trainer.AadharNumber}
                    </td>

                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        color: "#333",
                      }}
                    >
                      <p onClick={() => setIsVisible(!isVisible)}>Show</p>
                      {isVisible && trainer.Password}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
