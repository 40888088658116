// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// export default function CreateBatch() {
//   const [CourseData, setCourseData] = useState([]);
//   const [selectedCourseId, setSelectedCourseId] = useState(null);
//   //const [BatchForm, setBatchForm] = useState(false);
//   const [batchForms, setBatchForms] = useState({}); // State to store form data for each course

//   // const [BatchTitle, setBatchTitle] = useState("");
//   // const [BatchDetails, setBatchDetails] = useState("");
//   // const [BatchDuration, setBatchDuration] = useState("");

//   const getCourse = async () => {
//     try {
//       const response = await axios.get(
//         `http://localhost:5000/api/course/GetCourse`
//       );
//       console.log("The response is", response.data);
//       setCourseData(response.data);
//     } catch (error) {
//       console.log("there is error", error);
//     }
//   };

//   useEffect(() => {
//     getCourse();
//   }, []);

//   const CreateCourseBatch = async (_id) => {
//     try {
//       const response = await axios.patch(
//         `http://localhost:5000/api/CreateCourseBatch/${_id}`,
//         { NewsPause: true }
//       );
//       alert("Batch is created");
//       // navigate("/ReporterApproval")
//       window.location.reload();
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const BatchFormModel = () => {
//     setBatchForm(true);
//   };
//   const BatchFormModelClose = () => {
//     setBatchForm(false);
//   };

//   // const submitHandler = async (_id) => {
//   //    e.preventDefault();
//   //   const formData = new FormData();
//   //   formData.append("BatchTitle", BatchTitle);
//   //   formData.append("BatchDetails", BatchDetails);
//   //   formData.append("BatchDuration", BatchDuration);

//   //   try {
//   //     const response = await axios.patch(
//   //       `http://localhost:5000/api/course/CreateCourseBatch/${_id}`,
//   //       formData,
//   //       {          // headers: {
//   //         //   "Content-type": "multipart/form-data",
//   //         // },
//   //       }
//   //     );
//   //     alert("Your batch is uploaded");
//   //     console.log("Batch uploaded successfully", response.data);
//   //     // setBatchTitle("");
//   //     // setBatchDetails("");
//   //     // setBatchDuration("");
//   //     // setBatchForm(null);
//   //   } catch (error) {
//   //     console.log("batch not uploaded ---", error.response || error.message);
//   //   }
//   // };

//   const submitHandler = async (_id) => {
//     try {
//       const { BatchTitle, BatchDetails, BatchDuration } = batchForms[_id] || {};
//       const payload = { BatchTitle, BatchDetails, BatchDuration };

//       //const payload = { BatchTitle, BatchDetails, BatchDuration }  = batchForms[_id] || {};

//       const response = await axios.patch(
//         `http://localhost:5000/api/course/CreateCourseBatch/${_id}`,
//         payload
//       );

//       alert("Batch is created successfully");
//       console.log("Batch uploaded successfully", response.data);

//       // Clear form fields after submission
//       // setBatchTitle("");
//       // setBatchDetails("");
//       // setBatchDuration("");
//       //setBatchForm(null); // Close the form after submission

//       setBatchForms((prevForms) => ({
//         ...prevForms,
//         [_id]: { BatchTitle: "", BatchDetails: "", BatchDuration: "" },
//       }));
//       setSelectedCourseId(null); // Close the form after submission
//     } catch (error) {
//       console.log("Batch not uploaded ---", error.response || error.message);
//     }
//   };

//   const handleInputChange = (_id, field, value) => {
//     setBatchForms((prevForms) => ({
//       ...prevForms,
//       [_id]: { ...prevForms[_id], [field]: value },
//     }));
//   };

//   return (
//     <div>
//       <h1 style={{ textAlign: "center" }}>Create Batch</h1>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           padding: 10,
//           gap: "50px",
//           justifyContent: "space-around",
//           flexWrap: "wrap",
//         }}
//       >
//         {CourseData.map((item, index) => (
//           <div key={item._id}>
//             <div
//               style={{
//                 backgroundColor: "white",
//                 boxShadow:
//                   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
//                 borderRadius: 10,
//                 // width: "25%",
//                 // height: "25%",
//               }}
//             >
//               <p
//                 style={{
//                   textAlign: "center",
//                   fontWeight: "bold",
//                   color: "black",
//                   fontSize: 24,
//                 }}
//               >
//                 {item.Title}
//               </p>
//               {/* <Link
//                 style={{ textDecoration: "none" }}
//                 to={{ pathname: `/Batches/${item._id}` }}
//               >
//                 OPEN
//               </Link> */}

//               <div style={{ padding: 10 }}>
//                 <ul>
//                   <li> {item.Details}</li>
//                   <li> {item.Mode}</li>
//                   <li> {item.Language}</li>
//                 </ul>
//               </div>

//               <div>
//                 <div
//                   style={{
//                     display: "block",
//                     marginRight: "auto",
//                     marginLeft: "auto",
//                     backgroundColor: "orange",
//                     width: "80%",
//                     borderRadius: 5,
//                     padding: 0.5,
//                   }}
//                   // onClick={BatchFormModel}
//                   // onClick={() => CreateCourseBatch(item._id)}
//                   onClick={() => setSelectedCourseId(item._id)}
//                 >
//                   <p
//                     style={{
//                       color: "whitesmoke",
//                       fontWeight: "bold",
//                       textAlign: "center",
//                     }}
//                   >
//                     Add a batch
//                   </p>
//                 </div>
//               </div>

//               {/* {BatchForm && ( */}
//               {selectedCourseId === item._id && (
//                 <div style={{ padding: 10 }}>
//                   <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
//                     Create a batch
//                   </h3>
//                   <p
//                     //onClick={BatchFormModelClose}
//                     onClick={() => setSelectedCourseId(null)}
//                     style={{
//                       color: "red",
//                       textAlign: "right",
//                       fontWeight: "bold",
//                       cursor: "pointer",
//                     }}
//                   >
//                     Close
//                   </p>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       padding: 20,
//                     }}
//                   >
//                     <label style={{ color: "black", fontWeight: "bold" }}>
//                       Batch Title :
//                     </label>
//                     <input
//                       type="text"
//                       placeholder="Batch Title"
//                       value={batchForms[item._id]?.BatchTitle || ""}
//                       onChange={(e) =>
//                         handleInputChange(
//                           item._id,
//                           "BatchTitle",
//                           e.target.value
//                         )
//                       }
//                       //  onChange={(e) => setBatchTitle(e.target.value)}
//                     />

//                     <label style={{ color: "black", fontWeight: "bold" }}>
//                       Batch Details :
//                     </label>
//                     <input
//                       type="text"
//                       placeholder="Batch Details"
//                       // value={BatchDetails}
//                       // onChange={(e) => setBatchDetails(e.target.value)}
//                       value={batchForms[item._id]?.BatchDetails || ""}
//                       onChange={(e) =>
//                         handleInputChange(
//                           item._id,
//                           "BatchDetails",
//                           e.target.value
//                         )
//                       }
//                     />

//                     <label style={{ color: "black", fontWeight: "bold" }}>
//                       Batch Duration :
//                     </label>
//                     <input
//                       type="text"
//                       placeholder="Batch Duration"
//                       // value={BatchDuration}
//                       // onChange={(e) => setBatchDuration(e.target.value)}
//                       value={batchForms[item._id]?.BatchDuration || ""}
//                       onChange={(e) =>
//                         handleInputChange(
//                           item._id,
//                           "BatchDuration",
//                           e.target.value
//                         )
//                       }
//                     />

//                     <div
//                       // onClick={submitHandler}
//                       onClick={() => submitHandler(item._id)}
//                     >
//                       <p
//                         style={{
//                           backgroundColor: "blue",
//                           color: "white",
//                           textAlign: "center",
//                           borderRadius: 10,
//                         }}
//                       >
//                         Submit
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {/* )} */}
//               <Link
//                 style={{ textDecoration: "none", color: "black" }}
//                 to={{ pathname: `/Batches/${item._id}` }}
//               >
//                 <img
//                   style={{
//                     marginTop: 10,
//                     display: "flex",
//                     marginRight: "auto",
//                     marginLeft: "auto",
//                     // width: "100%",
//                     // height: "50%",
//                     alignSelf: "center",
//                     borderBottomRightRadius: 10,
//                     borderBottomLeftRadius: 10,
//                     height: "180px",
//                     width: "250px",
//                   }}
//                   src={`http://localhost:5000/${item.Image}`}
//                 />
//               </Link>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Constant";

export default function CreateBatch() {
  const [CourseData, setCourseData] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [batchForms, setBatchForms] = useState({});

  const getCourse = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/course/GetCourse`);
      console.log("The response is", response.data);
      setCourseData(response.data);
    } catch (error) {
      console.log("there is error", error);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  const submitHandler = async (_id) => {
    try {
      const { BatchTitle, BatchDetails, BatchDuration } = batchForms[_id] || {};
      const payload = { BatchTitle, BatchDetails, BatchDuration };

      const response = await axios.patch(
        `${BASE_URL}/api/course/CreateCourseBatch/${_id}`,
        payload
      );

      alert("Batch is created successfully");
      console.log("Batch uploaded successfully", response.data);

      setBatchForms((prevForms) => ({
        ...prevForms,
        [_id]: { BatchTitle: "", BatchDetails: "", BatchDuration: "" },
      }));
      setSelectedCourseId(null);
    } catch (error) {
      console.log("Batch not uploaded ---", error.response || error.message);
    }
  };

  const handleInputChange = (_id, field, value) => {
    setBatchForms((prevForms) => ({
      ...prevForms,
      [_id]: { ...prevForms[_id], [field]: value },
    }));
  };

  // const deleteCourse = async (_id) => {
  //   try {
  //     // const { BatchTitle, BatchDetails, BatchDuration } = batchForms[_id] || {};
  //     const payload = {
  //       CourseID: _id,
  //     };
  //     const response = await axios.delete(
  //       `${BASE_URL}/api/course/DeleteCourse/${_id}`,
  //       payload
  //     );

  //     alert("Batch is deleted successfully");
  //     console.log("Batch deleted successfully", response.data);
  //   } catch (error) {
  //     console.log("Batch not deleted ---", error.response || error.message);
  //   }
  // };

  const deleteCourse = async (_id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/course/DeleteCourse/${_id}`, {
        CourseID: _id 
      });
      console.log("here is the id",_id)
      alert("Course is deleted successfully");
      console.log("Course deleted successfully", response.data);
    } catch (error) {
      console.log("Course not deleted ---", error.response || error.message);
    }
  };
  
  return (
    <div style={styles.container}>
      <h1
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          fontWeight: "bold",
          color: "white",
          padding: "20px",
          backgroundImage: "linear-gradient(to right, #6a11cb, #2575fc)",
          borderRadius: "10px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
          margin: "20px auto",
          width: "20%",
        }}
      >
        Create Batch
      </h1>
      <div style={styles.cardContainer}>
        {CourseData.map((item) => (
          <div key={item._id} style={styles.card}>
            <p style={styles.courseTitle}>{item.Title}</p>

            <div style={styles.courseDetails}>
              <ul>
                <li>{item.Details}</li>
                <li>{item.Mode}</li>
                <li>{item.Language}</li>
              </ul>
            </div>
            <p
              onClick={() => deleteCourse(item._id)}
              style={{ color: "red", fontWeight: "bold",cursor:'grab' }}
            >
              Delete : Don't try
              {/* {item._id} */}
            </p>
            <div
              style={styles.addButton}
              onClick={() => setSelectedCourseId(item._id)}
            >
              <p style={styles.addButtonText}>Add a batch</p>
            </div>

            {selectedCourseId === item._id && (
              <div style={styles.formContainer}>
                <h3 style={styles.formTitle}>Create a batch</h3>
                <p
                  onClick={() => setSelectedCourseId(null)}
                  style={styles.closeButton}
                >
                  Close
                </p>
                <div style={styles.form}>
                  <label style={styles.label}>Batch Title:</label>
                  <input
                    type="text"
                    placeholder="Batch Title"
                    value={batchForms[item._id]?.BatchTitle || ""}
                    onChange={(e) =>
                      handleInputChange(item._id, "BatchTitle", e.target.value)
                    }
                    style={styles.input}
                  />

                  <label style={styles.label}>Batch Details:</label>
                  <input
                    type="text"
                    placeholder="Batch Details"
                    value={batchForms[item._id]?.BatchDetails || ""}
                    onChange={(e) =>
                      handleInputChange(
                        item._id,
                        "BatchDetails",
                        e.target.value
                      )
                    }
                    style={styles.input}
                  />

                  <label style={styles.label}>Batch Duration:</label>
                  <input
                    type="text"
                    placeholder="Batch Duration"
                    value={batchForms[item._id]?.BatchDuration || ""}
                    onChange={(e) =>
                      handleInputChange(
                        item._id,
                        "BatchDuration",
                        e.target.value
                      )
                    }
                    style={styles.input}
                  />

                  <div
                    onClick={() => submitHandler(item._id)}
                    style={styles.submitButton}
                  >
                    <p style={styles.submitButtonText}>Submit</p>
                  </div>
                </div>
              </div>
            )}

            <Link to={{ pathname: `/Batches/${item._id}` }} style={styles.link}>
              <img
                src={`${BASE_URL}/${item.Image}`}
                style={styles.image}
                alt="Course"
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#f7f7f7",
  },
  title: {
    textAlign: "center",
    fontSize: "36px",
    fontWeight: "bold",
    color: "#333",
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
  },
  card: {
    backgroundColor: "#fff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    padding: "20px",
    width: "300px",
    textAlign: "center",
  },
  courseTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#222",
  },
  courseDetails: {
    marginTop: "10px",
    textAlign: "left",
  },
  addButton: {
    marginTop: "10px",
    backgroundColor: "#FFA500",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  addButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: "16px",
  },
  formContainer: {
    marginTop: "15px",
    borderRadius: "8px",
    backgroundColor: "#f1f1f1",
    padding: "15px",
  },
  formTitle: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  closeButton: {
    color: "red",
    textAlign: "right",
    fontWeight: "bold",
    cursor: "pointer",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: "#333",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    marginBottom: "10px",
  },
  submitButton: {
    backgroundColor: "#007bff",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  submitButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  link: {
    textDecoration: "none",
  },
  image: {
    width: "100%",
    height: "180px",
    borderRadius: "8px",
    marginTop: "10px",
  },
};
