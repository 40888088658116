import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Subadmin = () => {
  const [adminlist, setadminlist] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdmindata = async () => {
      try {
        const response = await axios.get(
          "http://localhost:5000/api/SubAdmin/getAllAdminlist"
        );
        console.log("this is the response", response);
        setadminlist(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAdmindata();
  }, []);

  const AdminApproval = async (_id) => {
    try {
      const response = await axios.patch(
        `http://localhost:5000/api/SubAdmin/AdminApproval/${_id}`,
        { AdminApproved: true }
      );
      alert("Reporter is now Verified");
      // navigate("/ReporterApproval")
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const AdminDisapproval = async (_id) => {
    try {
      const response = await axios.patch(
        `http://localhost:5000/api/SubAdmin/AdminDisapproval/${_id}`,
        { AdminApproved: false }
      );
      alert("Reporter is now removed ❌");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        marginTop: "10px",
        marginRight: "100px",
        marginLeft: "100px",
        backgroundColor: "white",
        padding: "10px",
        color: "black",
        boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 12px",
      }}
    >
      <h1 style={{ textAlign: "center", color: "black", fontWeight: "bold" }}>
        Sub Admin
      </h1>

      <table
        style={{
          width: "100%",
          // borderCollapse: "collapse",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              padding: "5px",
              margin: "10px",
              // position: "sticky",
              borderRadius: "10px",
              backgroundColor: "#f9f9f9",
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
            }}
          >
            <th style={{ padding: "10px" }}>Approve</th>
            <th style={{ padding: "10px" }}>Name</th>
            <th style={{ padding: "10px" }}>Mail</th>
            <th style={{ padding: "10px" }}>Number</th>
            <th style={{ padding: "10px" }}>State</th>
            <th style={{ padding: "10px" }}>City</th>
          </tr>
        </thead>
        <tbody>
          {adminlist
            .filter((item) => item.role === "SubAdmin")
            .toReversed()
            .map((item) => (
              <tr
                key={item._id}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  borderRadius: "10px",
                  padding: "5px",
                  margin: "10px",
                  textAlign: "left",
                }}
              >
                <td style={{ padding: "10px" }}>
                  {item.AdminApproved === false ? (
                    <button
                      onClick={() => AdminApproval(item._id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px 20px",
                        borderRadius: "50px",
                        border: "2px solid lightblue",
                        backgroundColor: "#f0f8ff",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <p
                        style={{
                          color: "lightblue",
                          fontWeight: "bold",
                          margin: 0,
                          transition: "color 0.3s ease",
                        }}
                      >
                        Disapproved ✖ click to get approve
                      </p>
                    </button>
                  ) : (
                    <button
                      onClick={() => AdminDisapproval(item._id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px 20px",
                        borderRadius: "50px",
                        border: "2px solid lightgreen",
                        backgroundColor: "#f0fff0",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <p
                        style={{
                          color: "lightgreen",
                          fontWeight: "bold",
                          margin: 0,
                          transition: "color 0.3s ease",
                        }}
                      >
                        Approved ✔ click to get Disapprove
                      </p>
                    </button>
                  )}
                </td>
                <td style={{ padding: "10px" }}>{item.AdminName}</td>
                <td style={{ padding: "10px" }}>{item.AdminMail}</td>
                <td style={{ padding: "10px" }}>{item.AdminNumber}</td>
                <td style={{ padding: "10px" }}>{item.AdminState}</td>
                <td style={{ padding: "10px" }}>{item.AdminDistrict}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default Subadmin;
