// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { BASE_URL } from "../../Constant";
// import StudentsList from "../Course/StudentsList";
// import { Link } from "react-router-dom";

// export default function StudentBatches() {
//   const [userMail, setUserMail] = useState("");
//   const [userRole, setuserRole] = useState("");
//   ///  const [courses, setCourses] = useState();
//   const [StudentBatchList, SetStudentBatch] = useState([]);
//   //   const [lectureInputs, setLectureInputs] = useState({});
//   //   const [lecturebox, setlecturebox] = useState(null);
//   const [LectureBox, SetLectureBox] = useState(null);
//   const [openSpecificlec, SetopenSpecificlec] = useState(null);

//   const LoggedUser = () => {
//     const whichUserSignedIn = localStorage.getItem("UserInfo");

//     if (whichUserSignedIn) {
//       try {
//         const userInfo = JSON.parse(whichUserSignedIn);

//         if (userInfo && userInfo.UserId && userInfo.UserId.Mail) {
//           setUserMail(userInfo.UserId.Mail);
//           setuserRole(userInfo.UserId.role);

//           console.log(
//             "User's Mail:............................",
//             userInfo.UserId.Mail
//           );
//           console.log("User's Mail:", userInfo.UserId.role);
//           console.log("User's Mail:", userInfo.UserId.Name);
//         } else {
//           console.log("Mail property not found in UserInfo.");
//         }
//       } catch (error) {
//         console.error("Error parsing JSON:", error);
//       }
//     } else {
//       console.log("UserInfo not found in localStorage.");
//     }
//   };

//   const getCourse = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/course/GetCourse`);
//       const courses = response.data;
//       console.log("Courses list", courses);
//       console.log("User mail is", userMail);
//       SetStudentBatch(courses);

//       //   const Student = courses.map((item) =>
//       //     item.CourseBatch.map((Batches) =>
//       //       Batches.Students.filter((StudentBatch) =>
//       //         StudentBatch.email === userMail ? item.CourseBatch : null
//       //       )
//       //     )
//       //   );

//       // const studentBatches = courses.flatMap((course) =>
//       //     course.CourseBatch.filter((batch) =>
//       //       batch.Students.some((student) => student.email === userMail)
//       //     )
//       //   );

//       //   console.log("Student Batches list", Student);

//       //   const trainerBatches = courses.flatMap((item) =>
//       //     item.CourseBatch.filter((batch) => batch.BatchTrainerEmail === userMail)
//       //   );
//       // setCourses(courses);
//     } catch (error) {
//       console.log("there is error", error);
//     }
//   };

//   useEffect(() => {
//     LoggedUser();
//   }, []);

//   useEffect(() => {
//     if (userMail) {
//       getCourse();
//     }
//   }, [userMail]);
//   return (
//     //     <div>
//     //       <p>ullu : {userMail}</p>
//     //       {/* {StudentBatchList.map((item, index) => ( */}
//     //       {/* <div key={item._id}> */}
//     //       {/* {item.map((StuBatch)=>(
//     //     <div key={StuBatch._id}>
//     //         <p>{StuBatch}</p>
//     //     </div>
//     // ))} */}
//     //       {/* </div>
//     //       ))} */}

//     //       {/* <p>{StudentBatchList}</p> */}
//     //       {/*
//     // {StudentBatchList.length > 0 ? (
//     //       StudentBatchList.map((batch, index) => (
//     //         <div key={batch._id}>
//     //           <p>Batch Title: {batch.BatchTitle}</p>
//     //         </div>
//     //       ))
//     //     ) : (
//     //       <p>No batches found for this user.</p>
//     //     )} */}

//     //       {/*
//     //       {StudentBatchList.map((item, index) => {
//     //         <div key={item._id}>
//     //           <p>{item.BatchTitle}</p>
//     //         </div>
//     // })} */}

//     //       {/*
//     //       {StudentBatchList.map((item, index) =>
//     //         item.map((stuBatch) =>
//     //           stuBatch.map((batchdata) => (
//     //             <div key={batchdata._id}>
//     //               <div
//     //               style={{
//     //                 margin: "10px",
//     //                 padding: "10px",
//     //                 display: "flex",
//     //                 flexDirection: "column",
//     //                 justifyContent: "center",
//     //                 alignItems: "center",
//     //                 width: "auto",
//     //                 // height: "200px",
//     //                 backgroundColor: "white",
//     //                 boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//     //                 color: "black",
//     //                 borderRadius: "8px",
//     //                 cursor: "pointer",
//     //                 borderColor: "red",
//     //               }}
//     //               >
//     //                 <p>{batchdata.BatchTitle}</p>
//     //               </div>
//     //             </div>
//     //           ))
//     //         )
//     //       )} */}

//     //       {StudentBatchList.map(
//     //         (item, index) =>
//     //           item.CourseBatch.map(
//     //             (batch, index) =>
//     //               batch.Students.map((stu, index) => (
//     //                 // stu.email = userMail ? "verified" : "null"
//     //                 <div style={{display:'flex',}} key={stu._id}>
//     //                   {stu.email === userMail ? (
//     //                     <div
//     //                       style={{
//     //                         backgroundColor: "white",
//     //                         boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
//     //                         width:'200px',
//     //                         fontWeight:'bold'
//     //                       }}
//     //                     >
//     //                       <p>{batch.BatchTitle}</p>
//     //                       <p>{batch.BatchDetails}</p>
//     //                       {/* <p>{batch.BatchTitle}</p>
//     //                       <p>{batch.BatchTitle}</p> */}

//     //                     </div>
//     //                   ) : null}
//     //                 </div>
//     //               ))

//     //             //     <div key={batch._id}>
//     //             // <p>{batch.BatchTitle}</p>
//     //             //     </div>
//     //           )
//     //         // <div key={item._id}>
//     //         // <p>{item.Title}</p>
//     //         // </div>
//     //       )}
//     //     </div>

//     <div
//       style={{
//         margin: "10px",
//         display: "flex",
//         flexWrap: "wrap",
//         gap: "10px", // Consistent gap between items
//       }}
//     >
//       {StudentBatchList.map((item) =>
//         item.CourseBatch.map((batch) =>
//           batch.Students.map((stu) =>
//             stu.email === userMail ? (
//               <div
//                 style={{
//                   backgroundColor: "white",
//                   boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
//                   width: "auto",
//                   padding: "10px", // Adds padding inside the card
//                   borderRadius: "8px", // Optional styling for better appearance
//                 }}
//                 key={stu._id}
//               >
//                 <p style={{ fontWeight: "bold" }}>
//                   {batch.BatchTitle.toUpperCase()}
//                 </p>
//                 <p>{batch.BatchDetails}</p>

//                 <div
//                   onClick={() => SetLectureBox(batch._id)}
//                   style={{
//                     backgroundColor: "black",
//                     color: "white",
//                     padding: "1px",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <p style={{ textAlign: "center" }}>Lectures</p>
//                 </div>
//                 {LectureBox === batch._id && (
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       gap: "10px",
//                     }}
//                   >
//                     <p style={{color:'red',fontWeight:'bold',textAlign:'center',cursor:'pointer'}} onClick={()=>SetLectureBox(null)}>Close</p>

//                     {batch.lectures.map((apnaLec, index) => (
//                       <div
//                         style={{
//                           backgroundColor: "white",
//                           boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
//                           padding: "10px",
//                         }}
//                       >
//                         <div
//                           style={{
//                             backgroundColor: "whitesmoke",
//                             borderRadius: "10px",
//                           }}
//                           onClick={() => SetopenSpecificlec(apnaLec._id)}
//                         >
//                           <p
//                             style={{
//                               fontWeight: "bold",
//                               textAlign: "center",
//                               cursor: "pointer",
//                             }}
//                           >
//                             {apnaLec.title}
//                           </p>
//                         </div>

//                         {openSpecificlec === apnaLec._id && (
//                           <div>
//                             <p
//                               style={{
//                                 color: "red",
//                                 fontWeight: "bold",
//                                 cursor: "pointer",
//                                 textAlign: "right",
//                               }}
//                               onClick={() => SetopenSpecificlec(null)}
//                             >
//                               Close
//                             </p>
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "row",
//                                 gap: "5px",
//                               }}
//                             >
//                               <p style={{ fontWeight: "bold" }}>Lecture No :</p>
//                               <p>{apnaLec.title}</p>
//                             </div>

//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "row",
//                                 gap: "5px",
//                                 alignItems:'center'
//                               }}
//                             >
//                               <p style={{ fontWeight: "bold" }}>Link : </p>

//                               <Link to={apnaLec.link}>{apnaLec.link}</Link>
//                             </div>

//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "row",
//                                 gap: "5px",
//                                 alignItems:'center'
//                               }}
//                             >
//                               <p style={{ fontWeight: "bold" }}>Details : </p>

//                             <p>{apnaLec.details}</p>

//                             </div>

//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "row",
//                                 gap: "5px",
//                                 alignItems:'center'
//                               }}
//                             >
//                             <p style={{ fontWeight: "bold" }}>Duration : </p>

//                             <p>{apnaLec.duration}</p>

//                             </div>

//                           </div>
//                         )}
//                       </div>
//                     ))}
//                   </div>
//                 )}
//               </div>
//             ) : null
//           )
//         )
//       )}
//     </div>
//   );
// }
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Constant";
import { Link } from "react-router-dom";

export default function StudentBatches() {
  const [userMail, setUserMail] = useState("");
  const [userRole, setuserRole] = useState("");
  const [StudentBatchList, SetStudentBatch] = useState([]);
  const [LectureBox, SetLectureBox] = useState(null);
  const [openSpecificlec, SetopenSpecificlec] = useState(null);

  const LoggedUser = () => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userInfo = JSON.parse(whichUserSignedIn);
        if (userInfo && userInfo.UserId && userInfo.UserId.Mail) {
          setUserMail(userInfo.UserId.Mail);
          setuserRole(userInfo.UserId.role);
        } else {
          console.log("Mail property not found in UserInfo.");
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("UserInfo not found in localStorage.");
    }
  };

  const getCourse = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/course/GetCourse`);
      SetStudentBatch(response.data);
    } catch (error) {
      console.log("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    LoggedUser();
  }, []);

  useEffect(() => {
    if (userMail) {
      getCourse();
    }
  }, [userMail]);

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "center",
        // backgroundColor:'black'
      }}
    >
      {StudentBatchList.toReversed().map((item) =>
        item.CourseBatch.map((batch) =>
          batch.Students.map((stu) =>
            stu.email === userMail ? (
              <div
                key={stu._id}
                style={{
                  backgroundColor: "#ffffff",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                  minWidth: "300px",
                  width: "100%",
                  padding: "15px",
                  transition: "transform 0.2s, box-shadow 0.2s",
                }}
                // onMouseEnter={(e) => {
                //   e.currentTarget.style.transform = "scale(1.03)";
                //   e.currentTarget.style.boxShadow = "0 6px 20px rgba(0, 0, 0, 0.2)";
                // }}
                // onMouseLeave={(e) => {
                //   e.currentTarget.style.transform = "scale(1)";
                //   e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
                // }}
              >
                {/* <p>{stu.name}</p> */}
                {/* <p>{stu.isVerified?.toString()}</p> */}

                {stu.isVerified === true ? (
                  <p style={{ color: "green", fontWeight: "bold" }}>Active</p>
                ) : (
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Not Activated
                  </p>
                )}

                <h3
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {batch.BatchTitle.toUpperCase()}
                </h3>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#555",
                    marginBottom: "10px",
                  }}
                >
                  {batch.BatchDetails}
                </p>

                {stu.isVerified === true ? (
                  <button
                    onClick={() => SetLectureBox(batch._id)}
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      padding: "8px 12px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      width: "100%",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    View Lectures
                  </button>
                ) : null}

                {LectureBox === batch._id && (
                  <div style={{ marginTop: "10px" }}>
                    <p
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => SetLectureBox(null)}
                    >
                      Close Lectures
                    </p>

                    {batch.lectures.map((apnaLec) => (
                      <div
                        key={apnaLec._id}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                          padding: "10px",
                          marginBottom: "10px",
                          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "bold",
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                          onClick={() => SetopenSpecificlec(apnaLec._id)}
                        >
                          {apnaLec.title}
                        </p>

                        {openSpecificlec === apnaLec._id && (
                          <div>
                            <p
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                textAlign: "right",
                                cursor: "pointer",
                              }}
                              onClick={() => SetopenSpecificlec(null)}
                            >
                              Close Details
                            </p>

                            <p>
                              <strong>Lecture No:</strong> {apnaLec.title}
                            </p>
                            <p>
                              <strong>Link:</strong>{" "}
                              <Link to={apnaLec.link}>{apnaLec.link}</Link>
                            </p>
                            <p>
                              <strong>Details:</strong> {apnaLec.details}
                            </p>
                            <p>
                              <strong>Duration:</strong> {apnaLec.duration}
                            </p>

                            <div
                              style={{
                                backgroundColor: "whitesmoke",
                                padding: "10px",
                              }}
                            >
                              <p>
                                <strong>Task:</strong> {apnaLec.tasktitle}
                              </p>
                              <p>
                                <strong>Description :</strong>
                                {apnaLec.taskdescription}
                              </p>
                              <p>
                                <strong>Start Date:</strong>
                                {apnaLec.taskStartDate}
                              </p>
                              <p>
                                <strong>End Date :</strong>
                                {apnaLec.taskEndDate}
                              </p>
                              <p>
                                <strong>Recording Link:</strong>
                                <Link to={apnaLec.recordinglink}>
                                  {apnaLec.recordinglink}
                                </Link>

                              </p>
                              {/* <strong>File :</strong> */}
                              <img style={{height:'50px',width:'50px'}} src={`${BASE_URL}/${apnaLec.lecturePdf}`} />

                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : null
          )
        )
      )}
    </div>
  );
}
