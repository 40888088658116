import React, { useEffect, useState } from "react";
import Header from "./Components/Navigation/Header";
import Dashboard from "./Screens/Dashboard";
import Upload from "./Screens/Upload";
import Subadmin from "./Screens/SubAdmin/Subadmin";
import Login from "./Screens/auth/Login";
import Signup from "./Screens/auth/Signup";
import ReporterApproval from "./Screens/Reporter/ReporterApproval";
import Nothing from "./Screens/Nothing";
import ApproveNews from "./Screens/News/ApproveNews";
import { Router, BrowserRouter, Link, Routes, Route } from "react-router-dom";
import CreateCourse from "./Screens/Course/CreateCourse";
import CreateBatch from "./Screens/Course/CreateBatch";
import Batches from "./Screens/Course/Batches";
import BatchupdateEdit from "./Screens/Course/BatchupdateEdit";
import CourseRegistration from "./Screens/Student/CourseRegistration";
import BatchRegistration from "./Screens/Student/BatchRegistration";
import TrainerBatchesList from "./Screens/Trainer/TrainerBatchesList";
// import StudentsList from "./Screens/Course/StudentsList";
import StudentsList from "./Screens/Course/StudentsList";
import UploadLecture from "./Screens/Trainer/UploadLecture";
import StudentBatches from "./Screens/Student/StudentBatches";
import Home from "./Screens/InroPages/Home";
import Services from "./Screens/InroPages/Services";
import Courses from "./Screens/InroPages/Courses";
import ContactUs from "./Screens/InroPages/ContactUs";
import AboutUs from "./Screens/InroPages/AboutUs";
import CreateInternship from "./Screens/Internship/CreateInternship";
import CreateInternshipCohort from "./Screens/Internship/CreateInternshipCohort";
import InternshipCohort from "./Screens/Internship/InternshipsCohort";
import InternshipUpdateEdit from "./Screens/Internship/InternshipUpdateEdit";
import InternshipStudentList from "./Screens/Internship/InternshipStudentList";
import TrainerInternshipList from "./Screens/Trainer/TrainerInternshipList";
import InternshipCohortBatches from "./Screens/Student/InternshipCohortBatches";
import StudentCredentials from "./Screens/UserCredentials/StudentCredentials";
export default function App() {
  const [userDashboardRole, setuserDashboard] = useState(null);
  useEffect(() => {
    const whichUserSignedIn = localStorage.getItem("UserData");
    setuserDashboard(whichUserSignedIn);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Header />
        {userDashboardRole ? (
          <Routes>
            <Route path="/" element={<Dashboard />} exact />
            <Route path="/Home" element={<Home />} />
            <Route path="/Upload" element={<Upload />} />
            <Route path="/Subadmin" element={<Subadmin />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/ReporterApproval" element={<ReporterApproval />} />
            <Route path="/ApproveNews" element={<ApproveNews />} />
            <Route path="/CreateCourse" element={<CreateCourse />} />
            <Route path="/CreateBatch" element={<CreateBatch />} />
            <Route path="/Batches/:id" element={<Batches />} />
            <Route path="/BatchupdateEdit/:id" element={<BatchupdateEdit />} />
            <Route path="/StudentsList/:id" element={<StudentsList />} />
            <Route path="/UploadLecture/:id" element={<UploadLecture />} />
            <Route path="/StudentBatches" element={<StudentBatches />} />
            <Route path="/CreateInternship" element={<CreateInternship />} />
            <Route
              path="/CreateInternshipCohort"
              element={<CreateInternshipCohort />}
            />
            <Route
              path="/InternshipCohort/:id"
              element={<InternshipCohort />}
            />
            <Route
              path="/InternshipUpdateEdit/:id"
              element={<InternshipUpdateEdit />}
            />
            <Route
              path="/InternshipStudentList/:id"
              element={<InternshipStudentList />}
            />

            <Route
              path="/BatchRegistration/:id"
              element={<BatchRegistration />}
            />
            <Route
              path="/CourseRegistration"
              element={<CourseRegistration />}
            />
            <Route
              path="/TrainerBatchesList"
              element={<TrainerBatchesList />}
            />

            <Route
              path="/TrainerInternshipList"
              element={<TrainerInternshipList />}
            />

            <Route
              path="/InternshipCohortBatches"
              element={<InternshipCohortBatches />}
            />
            <Route
              path="/StudentCredentials"
              element={<StudentCredentials />}
            />

            {/* <Route path="/AboutUs"  element={<AboutUs/>}/>
            <Route path="/Services"  element={<Services/>}/>
            <Route path="/ContactUs"  element={<ContactUs/>}/>
            <Route path="/Courses"  element={<Courses/>}/> */}

            <Route path="*" element={<Dashboard />} exact />
          </Routes>
        ) : (
          <Routes>
            {/* <Route path="/" element={<Login />} exact /> */}
            <Route path="/" element={<Home />} exact />

            {/* <Route path="/Upload" element={<Upload />}  />
          <Route path="/Subadmin" element={<Subadmin />}  />*/}
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="*" element={<Login />} exact />
            <Route path="/Batches/:id" element={<Batches />} />

            <Route path="/Home" element={<Home />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Courses" element={<Courses />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}
