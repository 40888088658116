import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Constant";

export default function CourseRegistration() {
  const [CourseData, setCourseData] = useState([]);

  const getCourse = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/course/GetCourse`);
      console.log("The response is", response.data);
      setCourseData(response.data);
    } catch (error) {
      console.log("there is error", error);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <div style={{ padding: 10 }}>
      <h3>View Course 👁‍🗨</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 10,
          gap: "50px",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {CourseData.map((item, index) => (
          <div key={item._id}>
            <div
              style={{
                backgroundColor: "white",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                borderRadius: 10,
                // width: "25%",
                // height: "25%",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 24,
                }}
              >
                {item.Title}
              </p>
              {/* <Link
                style={{ textDecoration: "none" }}
                to={{ pathname: `/Batches/${item._id}` }}
              >
                OPEN
              </Link> */}

              <div style={{ padding: 10 }}>
                <ul>
                  <li> {item.Details}</li>
                  <li> {item.Mode}</li>
                  <li> {item.Language}</li>
                </ul>
              </div>
              {/* 
              <div>
                <div
                  style={{
                    display: "block",
                    marginRight: "auto",
                    marginLeft: "auto",
                    backgroundColor: "orange",
                    width: "80%",
                    borderRadius: 5,
                    padding: 0.5,
                  }}
                  // onClick={BatchFormModel}
                  // onClick={() => CreateCourseBatch(item._id)}
                  onClick={() => setSelectedCourseId(item._id)}
                >
                  <p
                    style={{
                      color: "whitesmoke",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Add a batch
                  </p>
                </div>
              </div> */}

              {/* 
<div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',}}>
    <div style={{backgroundColor:'orange',color:'white',borderRadius:1,padding:1,width:'50%'}}>
        <p style={{textAlign:'center'}} >Apply</p>
    </div>
    <div style={{backgroundColor:'red',color:'white',borderRadius:1,padding:1,width:'50%'}} >
        <p style={{textAlign:'center'}}>Remove</p>
    </div>
</div>
     */}
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={{ pathname: `/BatchRegistration/${item._id}` }}
              >
                <img
                  style={{
                    // marginTop: 10,
                    display: "flex",
                    marginRight: "auto",
                    marginLeft: "auto",
                    // width: "100%",
                    // height: "50%",
                    alignSelf: "center",
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    height: "180px",
                    width: "250px",
                  }}
                  src={`${BASE_URL}/${item.Image}`}
                />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
