import React, { useEffect, useState } from "react";
import user from "../../Assets/user.png";
import powerBtn from "../../Assets/power-off.png";
import login from "../../Assets/login.png";
import notification from "../../Assets/notification.png";
import Signup from "../../Assets/add-user (1).png";
import team from "../../Assets/relationship.png";
import group from "../../Assets/group.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../Assets/Css/Header.css";
export default function () {
  const [userData, setUserData] = useState(null);
  const [userDashboardRole, setuserDashboard] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userRole, setuserRole] = useState("");

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("UserData");
    setUserData(null);
    alert("User is logout");
    navigate("/Login");
    window.location.reload();
  };

  // useEffect(() => {
  //   // Check if user data exists in local storage and set it to state
  //   const storedUserData = localStorage.getItem("userData");
  //   if (storedUserData) {
  //     setUserData(JSON.parse(storedUserData));
  //   }
  // }, []);

  useEffect(() => {
    const whichUserSignedIn = localStorage.getItem("UserInfo");

    if (whichUserSignedIn) {
      try {
        const userInfo = JSON.parse(whichUserSignedIn);

        if (userInfo && userInfo.UserId && userInfo.UserId.Mail) {
          setuserDashboard(userInfo.UserId.Mail);

          console.log("User's Mail:", userInfo.UserId.role);
          setuserRole(userInfo.UserId.role);
        } else {
          console.log("Mail property not found in UserInfo.");
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("UserInfo not found in localStorage.");
    }
  }, []);

  useEffect(() => {
    const whichUserSignedIn = localStorage.getItem("UserData");
    setuserDashboard(whichUserSignedIn);
  }, []);

  const handleHamburgerClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div>
      <div className="headerbody">
        <div
          className="header-styling"
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   justifyContent: "space-between",
          //   alignItems: "center",
          //   paddingLeft: "15px",
          // }}
        >
          <div>
            <Link style={{ textDecoration: "none" }} to="/">
              <h1
              // style={{
              //   color: "white",
              //   fontWeight: "bold",
              //   fontFamily: "serif",
              //   backgroundColor: "#663dff",
              //   backgroundImage:
              //     "linear-gradient(319deg, #663dff 0%, #aa00ff 37%, #cc4499 100%)",
              //   borderRadius: "10px",
              //   padding: "5px",
              // }}
              >
                Bilvens
              </h1>
            </Link>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <img src={group} style={{ height: "40px", width: "40px" }} />
              {userRole === "SuperAdmin" && (
                <p style={{ fontWeight: "500" }}>Super Admin 🌟</p>
              )}
              {userRole === "Student" && (
                <p style={{ fontWeight: "500" }}>Student 👨‍🎓</p>
              )}
              {userRole === "Trainer" && (
                <p style={{ fontWeight: "500" }}>Trainer 👨‍🏫 </p>
              )}

              {/* {userDashboardRole === null && (
                <p style={{ fontWeight: "500" }}>Sign in</p>
              )} */}
            </div>

            <div className="hamburger" onClick={handleHamburgerClick}>
              &#9776;
            </div>

            <div className={`menu-items ${menuOpen ? "open" : ""}`}>
              <Link style={{ textDecoration: "none" }} to="/Signup">
                <img src={Signup} style={{ height: "35x", width: "35px" }} />
              </Link>
              <img
                src={notification}
                style={{ height: "40px", width: "40px" }}
              />
              <Link to="/login">
              <img src={user} style={{ height: "40px", width: "40px" }} />
              </Link>
              
              {userRole === null ? (
                <Link style={{ textDecoration: "none" }} to="/login">
                  <img src={login} style={{ height: "40px", width: "40px" }} />{" "}
                </Link>
              ) : (
                <img
                  onClick={handleLogout}
                  src={powerBtn}
                  style={{ height: "40px", width: "40px" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
